.box-text {
    display: block;
    margin-left: $box-text-padding-left;
    margin-top: -($page-gutter * 4);
    padding: ($page-gutter * 6) ($page-gutter * 3) ($page-gutter * 2);
    overflow: hidden;
    border: 1px solid $box-color-border;
    text-align: left;

    &__text {
        margin: 1em 0;
    }

    &__signature {
        float: right;
        height: 2.5em;
        width: 6em;

        svg {
            @extend %svg;
        }
    }

    @include media(md) {
        margin-top: -($page-gutter * 8);
        padding: ($page-gutter * 6) ($page-gutter * 4) ($page-gutter * 2);
    }

    @include media(xl) {
        margin-left: $box-text-padding-left * 2;
        margin-top: -($page-gutter * 10);
        padding: ($page-gutter * 8) ($page-gutter * 8) ($page-gutter * 4);

        &__signature {
            margin-top: $page-gutter * 2;
        }
    }


    @include media(xxl) {
        &__text {
            font-size: 18px;
        }
    }

    @each $breakpoint, $offset in $page-side-offsets {
        @include media($breakpoint) {
            $offset: map_get($page-side-offsets, $breakpoint);
            margin-right: -$offset;
            padding-right: $offset;
        }
    }
}
