.newsletter-form {
    @extend %grid;
    font-size: 8px;

    &__outer {
        @extend %grid__col;
    }

    &__submit {
        margin-top: $page-gutter * 2;
    }

    &__label {
        @extend .form-row__label;
        display: none;
    }

    @include media(xs, lg) {
        &__label {
            font-size: 10px;
        }
    }

    @include media(md) {
        &__outer {
            @extend %grid__col--6-md;
        }

        &__inner {
            display: flex;
            flex-direction: column;
        }

        &__submit {
            order: -1;
            margin: 0 0 ($page-gutter * 2);
        }

        &__label {
            display: block;
        }
    }

    @include media(lg) {
        font-size: 10px;
    }
}
