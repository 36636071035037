// stylelint-disable plugin/selector-bem-pattern

:root {
    --color-bg: transparent;
}

html {
    height: 100%;
    @include each-breakpoint($font-base-sizes, font-size);
}

body {
    min-height: 100%;
    min-width: $page-min-width;
    background: $page-color-bg;
    font: 300 1em/#{$font-base-line-height} $font-base-family;
    color: $page-color-text;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

strong {
    font-weight: 500;
}

img,
svg,
video,
audio,
embed,
object {
    max-width: 100%;
}

svg {
    max-height: 100%;
}

button {
    appearance: none;
}

// stylelint-enable plugin/selector-bem-pattern
