%link-simple {
    outline: 0;
    font: inherit;
    text-decoration: none;
    color: inherit;
    transition: opacity $transition-time / 2;

    &:hover,
    &:focus {
        opacity: .5;
    }
}
