.error {
    position: relative;
    text-align: center;

    &__code {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        display: flex;
        justify-content: center;
        align-items: center;
        font: 100 50vw $font-base-family;
        opacity: .1;
    }

    &__title {
        @include each-breakpoint($error-title-sizes, font-size);
        font-weight: 200;
    }

    &__lead {
        @include each-breakpoint($lead-sizes, font-size);
    }

    &__footer {
        @include each-breakpoint($section-paddings, margin-top, 'multiply', 1.5);
    }

    @media (orientation: landscape) {
        &__code {
            font-size: 50vh;
        }
    }
}
