.hr {
    display: block;
    width: $hr-width;
    margin: ($page-gutter * 2) 0;
    padding: 0;
    border: 0;
    border-top: $hr-height solid $hr-color;

    &--hidden {
        opacity: 0;
    }
}
