.wrapper {
    display: block;
    width: 100%;
    overflow: hidden; // needed as we have some element that are meant to be cut-off on smaller screens
    @include each-breakpoint($header-heights, padding-top); // to compensate fixed header height

    &__search {
        display: none;

        &.-has-results {
            display: block;
        }
    }

    &__content {
        &.-is-hidden {
            // d:n breaks slider
            max-height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
}
