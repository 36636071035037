$search-results-title-sizes: (
    'xs': 32px,
    'md': 44px,
    'xl': 56px,
);

.search-results {
    &__title {
        margin-bottom: $page-gutter * 2;
        @include each-breakpoint($search-results-title-sizes, font-size);
    }

    &__query {
        color: $search-results-query-color;
    }

    &__items {
        @extend %grid;

        &--empty {
            display: block;
            margin-bottom: $page-gutter * 4;
        }
    }

    &__item {
        @extend %grid__col;
        margin-bottom: $page-gutter * 1.5;
    }

    &__footer {
        display: flex;
        justify-content: center;
    }

    // algolia instant search
    // stylelint-disable-next-line plugin/selector-bem-pattern
    &__button[disabled] {
        display: none;
    }

    @include media(md) {
        &__title {
            margin-bottom: $page-gutter * 3;
        }

        &__item {
            @extend %grid__col--4-md;
        }
    }

    @include media(lg) {
        &__title {
            margin-bottom: $page-gutter * 4;
        }

        &__item {
            @extend %grid__col--3-lg;
        }
    }
}
