@keyframes header-sticky--show {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

.header {
    $root: &;
    position: absolute;
    top: 0;
    left: 0;
    z-index: z-index('header');
    display: block;
    width: 100%;
    background: $header-color-bg;
    animation: header-sticky--hide ($transition-time * 2) backwards;

    &__outer {
        position: relative;
        max-width: $page-max-width;
        margin: 0 auto;
        color: $header-color-text;
        transition: background ($transition-time * 2);

        &:hover {
            background: $header-color-bg;

            #{$root}__nav {
                border-bottom-color: transparent;
            }
        }
    }

    &__inner {
        position: relative;
    }

    &__logo {
        position: absolute;
        top: 0;
        right: 50%;
        z-index: 3;
        display: block;
        height: 100%;
        padding: $page-gutter;
        outline: 0;
        transform: translateX(50%);

        svg {
            @extend %svg;
            width: auto;
            margin: auto;
        }
    }

    &__bar {
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        // Header height for breakpoints
        @each $breakpoint, $height in $header-heights {
            @include media($breakpoint) {
                $nav-height: get-bp-value($header-nav-heights, $breakpoint);
                height: $height - $nav-height;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: stretch;
    }

    &__button {
        @extend %border-on-hover;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1em;
        margin: 0 $page-gutter;
        padding: 0;
        border: 0;
        border-radius: 0;
        background: transparent;
        color: inherit;
        font: 500 1.25em/1 $font-headings-family;
        letter-spacing: -.04em;
        cursor: pointer;

        &--close {
            &:hover,
            &:focus {
                #{$root}__icon {
                    transform: rotate(90deg);
                }
            }
        }

        &--desktop-only {
            display: none;
        }
    }

    &__button-text {
        position: relative;
    }

    &__icon {
        transition: transform $transition-time;
    }

    &__nav {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        display: none;
        height: 100vh;
        // stylelint-disable-next-line declaration-block-no-duplicate-properties
        height: calc(var(--vh, 1vh) * 100); // 100vh correction on mobile - see `viewport-correction.js`
        background: inherit;
        color: inherit;

        &.-is-active {
            display: flex;
            background: $header-color-bg;
        }
    }

    &__search {
        position: absolute;
        top: 0;
        z-index: 4; // logo + 1
        display: none;
        width: 100%;
        height: 100%;

        &.-is-visible {
            display: block;
        }
    }

    &--simple,
    &.-is-sticky {
        #{$root}__outer {
            @extend #{$root}__outer:hover;
        }
    }

    &.-is-sticky {
        position: fixed;
        animation: header-sticky--show ($transition-time * 2) forwards;
    }

    @include media(xl) {
        background: transparent;

        &__logo {
            padding: $page-gutter * 1.25;
        }

        &__nav {
            position: relative;
            top: 100%;
            display: block;
            height: auto;
            border-bottom: 1px solid $header-color-border;
            transition: border ($transition-time * 2);

            &.-is-active,
            &.-is-submenu-active {
                display: block !important; // stylelint-disable-line declaration-no-important
                background: none;
            }
        }

        &__bar {
            position: relative;
            z-index: 3;
            max-width: $page-max-width - (get-bp-value($page-side-paddings, xxl) * 2);
            margin: 0 auto;

            &--in-navigation {
                display: none;
            }
        }

        &__buttons {
            &:first-child {
                margin-left: -$page-gutter;
            }

            &:last-child {
                margin-right: -$page-gutter;
            }
        }

        &__button {
            &--menu {
                display: none;
            }

            &--desktop-only {
                display: flex;
            }

            &--close {
                display: none;

                &.-is-active {
                    display: flex !important; // stylelint-disable-line declaration-no-important
                }
            }

            &.-is-active {
                display: none;
            }
        }

        &.-is-sticky {
            #{$root} {
                &__nav {
                    display: none;
                }

                &__button {
                    &--menu {
                        display: block;
                    }
                }
            }
        }
    }
}
