.article-lead {
    @extend %grid;
    font-size: 14px;

    &__inner,
    &__date,
    &__share {
        @extend %grid__col;
    }

    &__date,
    &__share {
        font-size: .833em;
    }

    &__date {
        font-weight: 300;
        letter-spacing: .25em;
        color: $article-lead-date-color-text;
        opacity: 1;
    }

    &__content {
        font: 200 1em/#{1.667} $font-base-family;
    }

    &__text {
        margin: 1em 0;
        font: inherit;
    }

    @include media(md) {
        font-size: 16px;

        &__inner,
        &__date,
        &__share {
            @extend %grid__col--10-md;
            @extend %grid__col--padding-1-md;
        }
    }

    @include media(xl) {
        font-size: 28px;

        &__inner,
        &__date,
        &__share {
            @extend %grid__col--padding-0-xl;
        }

        &__inner {
            @extend %grid__col--8-xl;
        }

        &__date {
            @extend %grid__col--2-xl;
        }

        &__share {
            @extend %grid__col--margin-left-1-xl;
            @extend %grid__col--1-xl;
        }

        &__date,
        &__share {
            font-size: .5em;
        }

        &__content {
            margin: -1.333em 0;
        }

        &__text {
            line-height: 1.333;
        }
    }
}
