.slide {
    $root: &;
    @extend %media-aligned;
    flex-wrap: wrap;

    &__media {
        @extend %media-aligned__inner;
        @include each-breakpoint($slider-heights, height);

        &--video {
            @extend %media-aligned__inner--video;
        }

        // To correctly position play button on mobile devices
        // - we can do this as we don't show media markers on playable medias
        &--playable {
            width: 100%;
        }
    }

    &__image {
        @extend %media-aligned__image;
    }

    &__video {
        @extend %media-aligned__video;
    }

    &__caption {
        @extend %hyphen;
        @extend %hyphen--wide;
        width: 100%;
        margin-top: $page-gutter;
    }

    &--align-left {
        @extend %media-aligned--left;

        #{$root}__video {
            @extend %media-aligned__video--left;
        }
    }

    &--align-right {
        @extend %media-aligned--right;

        #{$root}__video {
            @extend %media-aligned__video--right;
        }
    }

    @include media(sm) {
        &--in-content {
            #{$root} {
                &__media {
                    width: 100%;
                    height: auto;
                }

                &__image {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
