.video-embed {
    position: relative;
    padding-bottom: percentage($video-proportion);
    background: $video-color-bg;
    color: $video-color-text;

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
