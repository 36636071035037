.contact-box {
    $root: &;
    margin-bottom: $page-gutter;

    &__label {
        margin-bottom: $page-gutter;

        &--empty {
            display: none;
        }
    }

    &__title {
        margin-bottom: $page-gutter;
    }

    &__text {
        margin: 0;
    }

    &__person {
        margin-bottom: $page-gutter;
    }

    @include media(md) {
        &__label {
            white-space: nowrap;

            &--empty {
                display: block;
                height: $page-gutter;
            }
        }

        &__people {
            &--columns {
                display: flex;
                flex-wrap: wrap;

                #{$root}__person {
                    width: 50%;
                }
            }
        }
    }

    @include media(lg) {
        &__label {
            &--empty {
                height: auto;
            }
        }

        &__people {
            &--columns {
                #{$root}__person {
                    &:nth-child(odd) {
                        width: percentage(4 / 7);
                    }

                    &:nth-child(even) {
                        width: percentage(3 / 7);
                        padding-left: $page-gutter;
                    }
                }
            }
        }
    }
}
