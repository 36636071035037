.news {
    @extend %animation;
    display: block;

    &__list {
        @extend %grid;
    }

    &__item {
        @extend %grid__col;
        @extend %animation__item;
        @extend %animation__item--from-bottom;

        &--featured {
            order: -1;
        }
    }

    @each $bp, $padding in $grid-vertical-paddings {
        @include media($bp) {
            &__list {
                margin-bottom: -($padding / 2);
            }

            &__item {
                margin: ($padding / 2) 0;
            }
        }
    }

    @include media(sm) {
        &__item {
            @extend %grid__col--6-sm;

            &--featured {
                @extend %grid__col--12-sm;
            }
        }
    }

    @include media(md) {
        &__item {
            @extend %grid__col--4-md;

            &--featured {
                @extend %grid__col--8-md;
            }
        }
    }

    @include media(lg) {
        &__item {
            @extend %grid__col--3-lg;

            &--featured {
                @extend %grid__col--6-lg;
                order: 0;
            }
        }
    }

    // Only if grid is supported (modern browsers and IE Edge)
    @supports (display: grid) {
        @include media(md) {
            &__list {
                display: grid;
                margin-left: 0; // reset %grid
                margin-right: 0; // reset %grid
                grid-template-columns: repeat(3, 1fr);
                @include each-breakpoint($grid-gutters, grid-column-gap);
            }

            &__item {
                // reset %grid__col
                display: block;
                padding: 0;
                width: auto;
                max-width: none;

                &--featured {
                    grid-column: 2 / -1;
                    grid-row: 1 / 3;
                }
            }
        }

        @include media(lg) {
            &__list {
                grid-template-columns: repeat(4, 1fr);
            }

            &__item {
                &--featured {
                    grid-column: 3 / -1;
                    grid-row: 1 / 3;
                }
            }
        }
    }
}
