%page-max-width {
    margin: 0 auto;
    max-width: 100%;

    @each $breakpoint, $width in $breakpoints {
        @if ($width > $page-min-width) {
            @include media($breakpoint) {
                max-width: $width * 1px;
            }
        }
    }

    &--narrow {
        max-width: $narrow-max-width;
    }
}
