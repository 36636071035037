.icon {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    color: inherit;

    svg {
        @extend %svg;
    }
}
