.banner {
    $root: &;
    position: relative;
    display: block;

    &__background {
        @extend %media-aligned;
    }

    &__media {
        @extend %media-aligned__inner;
        @include each-breakpoint($banner-image-heights, height);

        &--video {
            @extend %media-aligned__inner--video;
        }
    }

    &__image {
        @extend %media-aligned__image;
    }

    &__video {
        @extend %media-aligned__video;
    }

    &__inner {
        // ios fixes:
        z-index: 2;
        position: relative;
        transform: translate3d(0, 0, 0);
    }

    &__content {
        position: relative;
        z-index: 2;
        margin-top: -$page-gutter * 3;
        padding: $page-gutter * 3;
        background: $banner-color-bg;
        color: $banner-color-text;
    }

    &__description {
        @extend %hyphen;
        @extend %hyphen--wide;
        font-size: 1rem;
    }

    &__text {
        margin: 2em 0;
        font-size: inherit;
    }


    &--secondary {
        #{$root}__media {
            @include each-breakpoint($banner-secondary-image-heights, height);
        }
    }

    &--align-left {
        #{$root} {
            &__background {
                @extend %media-aligned--left;
            }

            &__video {
                @extend %media-aligned__video--left;
            }
        }
    }

    &--align-right {
        #{$root} {
            &__background {
                @extend %media-aligned--right;
            }

            &__video {
                @extend %media-aligned__video--right;
            }
        }
    }

    @include media(md) {
        &__content {
            margin-top: -$page-gutter * 4;
            padding: $page-gutter * 3 0;
        }

        &__row {
            @extend %grid--md;
        }

        &__col {
            @extend %grid__col--md;
            @extend %grid__col--10-md;
            @extend %grid__col--margin-1-md;
        }

        &__description {
            font-size: 16px;
        }
    }

    @include media(xl) {
        position: relative;

        &__media {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 40%;
                @include scrim-gradient($banner-color-bg, 'to top', .5);
                transform: translateZ(0);
                pointer-events: none;
            }
        }

        &__inner {
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            margin: ($page-gutter * 3) 0;
        }

        &__content {
            background: none;
        }

        &__col {
            @extend %grid__col--7-xl;
            @extend %grid__col--margin-0-xl;
            margin-left: auto;
        }

        &__description {
            font-size: 18px;
        }

        &__text {
            margin: 1.5em 0;
        }
    }
}
