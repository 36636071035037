.box {
    $root: &;
    position: relative;
    display: block;
    margin-bottom: $page-gutter * 4; // space for link
    outline: 0;
    color: inherit;
    text-decoration: none;
    text-align: left;

    &__image-wrapper {
        $image-width: get-map-value($boxes, 'alpha', 'width');
        $image-height: get-map-value($boxes, 'alpha', 'height');
        position: relative;
        display: block;
        overflow: hidden;
        padding-bottom: percentage($image-height / $image-width);
        background: $box-color-bg;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        transform: scale(1);
        transition: transform $transition-time;

        &--desktop {
            display: none;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        display: block;
        width: 8rem;
        height: 8rem;
        margin: -4rem;
        color: $box-color-icon;

        svg {
            @extend %svg;
            filter: drop-shadow(0 1em 1em $box-color-icon-shadow);
        }
    }

    &__content {
        position: absolute;
        bottom: -$box-text-offset;
        z-index: 2;
        display: block;
        width: 100%;

        &::after {
            content: '';
            position: absolute;
            bottom: $box-text-offset;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 200%;
            background: linear-gradient(to top, rgba($box-color-bg, .5) 0%, rgba($box-color-bg, 0) 100%);
        }
    }

    &__header {
        @each $bp, $offset in $box-content-offsets {
            @include media($bp) {
                padding-left: $offset;
                padding-right: $offset / 2;
            }
        }
    }

    &__title {
        color: $box-color-text;
    }

    &__badge {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
    }

    &__link {
        height: 0;
        margin-top: $box-link-offset;
        margin-bottom: -$box-link-offset; // to take "no space"
    }

    &:hover,
    &:focus {
        #{$root}__image {
            transform: scale($image-hover-scale);
        }
    }

    &--image-only {
        #{$root} {
            &__image-wrapper {
                padding: 0 !important; // stylelint-disable-line declaration-no-important
            }

            &__image {
                position: relative;
            }
        }
    }

    &--text-only {
        #{$root} {
            &__content {
                position: relative;

                &::after {
                    content: none;
                }
            }

            &__inner {
                margin-top: -1px;
                border: 1px solid $box-color-border;
                @include each-breakpoint($page-side-offsets, padding-right);
            }

            &__title {
                margin-top: -(1em - (($title-line-height - 1) * 1em));
            }

            &__link {
                height: auto;
                margin-bottom: 0;
            }

            &__footer {
                margin-top: $page-gutter * 3;
                margin-bottom: $page-gutter - 1px;
                padding: 0 $page-gutter;
                text-align: center;
            }
        }
    }

    @include media(xs, md) {
        &:not(&--with-link) {
            margin-bottom: $page-gutter;
        }
    }

    @include media(md) {
        &__image {
            &--mobile {
                display: none;
            }

            &--desktop {
                display: block;
            }
        }

        &--charlie {
            #{$root} {
                @each $bp, $grid-gutter in $grid-gutters {
                    @include media($bp) {
                        &__header {
                            padding-left: 0;
                            width: calc(125% + #{$grid-gutter / 2});
                            margin-left: calc(-25% - #{$grid-gutter / 2});
                        }
                    }
                }

                &__title {
                    padding-bottom: ($page-gutter * 6);
                }

                &__content {
                    &::after {
                        content: none;
                    }
                }

                &__image-wrapper {
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(to top, rgba($box-color-bg, .5) 0%, rgba($box-color-bg, 0) 100%);
                    }
                }
            }

            @each $bp, $offset in $box-content-offsets {
                @include media($bp) {
                    &#{$root}--even {
                        // stylelint-disable-next-line plugin/selector-bem-pattern
                        #{$root}__header {
                            margin-left: 0;
                            padding-left: $offset;
                            width: calc(125% + #{$offset + $page-gutter * 2});
                        }
                    }
                }
            }
        }

        &--text-only {
            #{$root} {
                &__title {
                    margin-top: $page-gutter;
                }

                &__link {
                    font-size: 1.25rem;
                }
            }
        }

        @each $type, $sizes in $boxes {
            $width: get-map-value($sizes, 'width');
            $height: get-map-value($sizes, 'height');

            &--#{$type} {
                #{$root}__image-wrapper {
                    padding-bottom: percentage($height / $width);
                }
            }
        }
    }

    @include media(lg) {
        &--text-only {
            #{$root} {
                &__footer {
                    text-align: left;
                }

                @each $bp, $offset in $box-content-offsets {
                    @include media($bp) {
                        $column-width: grid-column-width($bp);
                        $padding: if(
                            breakpoint($bp) >= breakpoint(xl),
                            $column-width + $page-gutter,
                            ($column-width * 2) + $page-gutter,
                        );

                        &__footer {
                            padding-left: $padding + $offset;
                        }

                        &__link {
                            padding-left: $padding;

                            &::after {
                                width: $padding - $page-gutter;
                            }
                        }
                    }
                }
            }
        }
    }

    @include media(xl) {
        &:not(&--text-only) {
            #{$root}__link {
                position: relative;
                left: $page-gutter / 2;
            }
        }

        &--text-only {
            #{$root} {
                &__footer {
                    margin-top: $page-gutter * 4;
                }
            }
        }
    }
}
