.documents {
    $root: &;
    padding: $page-gutter * 8;
    border: 1px solid $document-border-color;

    &__item {
        display: flex;
        align-items: center;
        padding: $page-gutter * 2 0 $page-gutter * 2 $page-gutter;
        border-bottom: 1px solid $document-border-color;
    }

    &__icon {
        margin-bottom: 0;
        margin-right: $page-gutter * 3;
    }

    &__download {
        margin-left: auto;
    }

    &__more {
        text-align: center;
    }

    &__archives {
        &.-is-hidden {
            display: none;
        }
    }

    &__button {
        margin-top: ($page-gutter * 9);
    }
}
