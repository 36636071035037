.text-with-colors {

    @include media(sm) {
        &__title-small {
            margin-bottom: $page-gutter * 1;
        }

        &__title-large {
            margin-bottom: $page-gutter * 2;
        }
    }
}
