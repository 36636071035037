%media-aligned {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    overflow: hidden;

    &__inner {
        flex-shrink: 0;
        min-width: 100%;

        &--video {
            width: 100%;
        }
    }

    &__video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;

        &--left {
            object-position: center left;
        }

        &--right {
            object-position: center right;
        }
    }

    &__image {
        display: block;
        height: 100%;
        width: auto;
        max-width: none;
    }

    &--left {
        justify-content: flex-start;
    }

    &--right {
        justify-content: flex-end;
    }
}
