$typ-image-size: 111px;
$typ-image-size-md: $typ-image-size * 1.5;
$typ-image-size-xxl: $typ-image-size * 2;
$typ-image-offset: $typ-image-size - ($page-gutter * 1.5);
$typ-image-color: $color-primary-light;
$typ-color-title: inherit;
$typ-color-text: $color-primary;
$typ-color-border: $border-color;

.typ {
    display: block;
    @include each-breakpoint($section-paddings, (padding-top, padding-bottom));

    &__inner {
        margin-top: $typ-image-offset;
        padding: 0 $page-gutter $page-gutter;
        border: 1px solid $typ-color-border;
    }

    &__image {
        display: block;
        width: $typ-image-size;
        height: $typ-image-size;
        max-width: 40%;
        margin: -$typ-image-offset auto ($page-gutter * 2);
        color: $typ-image-color;

        svg {
            @extend %svg;
        }
    }

    &__title {
        margin-bottom: .5em;
        color: $typ-color-title;
    }

    &__text {
        margin: 1em 0;
        font: 200 16px/1.25 $font-base-family;
        color: $typ-color-text;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__footer {
        margin-top: $page-gutter;
    }

    @include media(xs, md) {
        &__title {
            font-size: 32px;
        }
    }

    @include media(md) {
        &__inner {
            padding: 0 ($page-gutter * 2) ($page-gutter * 2);
        }

        &__footer {
            margin-top: $page-gutter * 2;
        }

        &__image {
            width: $typ-image-size-md;
            height: $typ-image-size-md;
        }

        &__text {
            font-size: 22px;
        }
    }

    @include media(xxl) {
        $border-offset: get-bp-value($page-side-paddings, xxl);

        &__inner {
            position: relative;
            margin: 0;
            padding: 0;
            border: 0;
        }

        &__content {
            margin: ($page-gutter * 2) 0 ($page-gutter * 2) (-$border-offset);
            padding: ($page-gutter * 2) 0 ($page-gutter * 2) $border-offset;
            border: 1px solid $typ-color-border;
        }

        &__content,
        &__title {
            padding-right: $typ-image-size-xxl + ($page-gutter * 12);
        }

        &__image {
            position: absolute;
            right: $page-gutter * 6;
            bottom: $page-gutter;
            width: $typ-image-size-xxl;
            height: $typ-image-size-xxl;
            margin-top: 0;
        }

        &__text {
            font-size: 28px;
        }
    }
}
