.address {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__content {
        @extend %hyphen;
        @extend %hyphen--wide;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        font-size: 1.333em;
    }

    &__title {
        margin-bottom: $page-gutter * 2;
        color: $color-primary;
    }

    &__heading {
        display: block;
    }

    &__footer {
        margin-top: auto;
        padding-top: $page-gutter * 3;
    }
}
