.info-boxes {
    $root: &;

    &__item {
        @extend %section-padding-set;
        font-size: 12px;

        @mixin info-box--type($color-bg, $color-text) {
            background: $color-bg;
            color: $color-text;
        }

        @each $type, $colors in $info-box-colors {
            @if $type == 'default' {
                @include info-box--type(nth($colors, 1), nth($colors, 2));
            } @else {
                &--#{$type} {
                    @include info-box--type(nth($colors, 1), nth($colors, 2));
                }
            }
        }
    }

    @mixin info-boxes--over($bp) {
        &--over {
            $offset: get-map-value($section-paddings, $bp) * 2;
            margin-top: -$offset;

            #{$root}__item {
                padding-top: $offset + ($page-gutter * 3);
            }
        }
    }

    @include media(xs, lg) {
        @each $bp, $padding in $page-side-paddings {
            margin-left: -$padding;
            margin-right: -$padding;

            &__item {
                padding-left: $padding;
                padding-right: $padding;
            }
        }
    }

    @include media(md) {
        @extend %grid--md;

        &__item {
            @extend %grid__col--md;
            @extend %grid__col--10-md;
            margin: 0 auto;
            font-size: 13px;
        }
    }

    @include media(lg) {
        &__item {
            @extend %grid__col--6-lg;
            @extend %grid__col--padding-1-lg;
            font-size: 13px;
        }

        @include info-boxes--over(lg);
    }

    // TODO: @norin89 grid extends are required due to code order and @extend issue
    @include media(xl) {
        @extend %grid--xl;

        &__item {
            @extend %grid__col--xl;
            @extend %grid__col--6-xl;
            @extend %grid__col--padding-1-xl;
        }

        @include info-boxes--over(xl);
    }
}
