.text {
    display: block;
    margin: 1em 0;
    font: 300 1rem/#{$text-line-height} $font-base-family;

    &--lead {
        line-height: $lead-line-height;
        @include each-breakpoint($lead-sizes, font-size);
    }

    &--condensed {
        margin: 0;
    }

    &--minor {
        line-height: $text-minor-line-height;
    }
}
