.map-marker {
    $root: &;
    width: 27px;
    height: 39px;
    transform: translate(-50%, -100%);
    cursor: pointer;

    &__body {
        fill: $map-marker-body;
    }

    &__dot {
        fill: $map-marker-dot;
    }

    &--highlighted {
        #{$root}__dot {
            fill: $map-marker-highlighted-dot;
        }
    }

    &--selected {
        #{$root}__body {
            fill: $map-marker-selected-body;
        }

        #{$root}__dot {
            fill: $map-marker-selected-dot;
        }
    }
}
