@keyframes marker-ripple {
    33.333% {
        opacity: 1;
        transform: scale3d(.75, .75, 1);
    }

    100% {
        opacity: 0;
        transform: scale3d(5, 5, 1);
    }
}

.marker {
    position: relative;
    width: 1em;
    height: 1em;
    margin: -.5em;
    padding: 0;
    border: 0;
    outline: 0;
    background: none;
    font-size: 22px;
    cursor: pointer;

    &__inner {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: $media-marker-color-bg;
        box-shadow: 0 0 0 .25em rgba($media-marker-color-shadow, .1);
        color: transparent;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: $media-marker-color-dot;
            transform: scale(.55);
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        border: .333em solid rgba($media-marker-color-shadow, .4);
        border-radius: 100%;
        transform: scale3d(.75, .75, 1);
        animation: marker-ripple ($media-marker-animation-time * 2) infinite cubic-bezier(.667, 0, .333, 1);
    }

    &::after {
        animation-delay: $media-marker-animation-delay;
    }

    @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
            $delay: ($i - 1) * ($media-marker-animation-delay * 2);

            &::before {
                animation-delay: $delay;
            }

            &::after {
                animation-delay: $delay + $media-marker-animation-delay;
            }
        }
    }
}
