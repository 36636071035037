.box-promo {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include each-breakpoint($box-promo-heights, min-height);
    padding: ($page-gutter * 4) ($page-gutter * 2) ($page-gutter * 2);
    outline: 0;
    background: get-map-value($badge-colors, 'alpha', 'background');
    color: get-map-value($badge-colors, 'alpha', 'color');
    text-align: left;
    text-decoration: none;
    transition: opacity $transition-time;

    &:focus {
        opacity: .75;
    }

    &__text {
        margin: ($page-gutter * 2) 0 ($page-gutter * 3);
    }

    @each $color, $values in $badge-colors {
        &--color-#{$color} { // stylelint-disable-line plugin/selector-bem-pattern
            background: get-map-value($values, 'background');
            color: get-map-value($values, 'color');
        }
    }

    @include media(md) {
        &__text {
            margin-bottom: $page-gutter * 3;
        }
    }

    @include media(md, lg) {
        &:not(&--in-heading) {
            width: calc(100% + #{grid-column-width('md')});
        }
    }

    @include media(lg) {
        padding: ($page-gutter * 6) ($page-gutter * 6) ($page-gutter * 3) ($page-gutter * 3);

        &__text {
            margin-bottom: $page-gutter * 4;
        }

        &--in-heading {
            min-height: $box-promo-in-heading-height;
        }
    }
}
