.record {
    @extend %grid;

    &__content,
    &__image {
        @extend %grid__col;
    }

    &__content {
        padding-top: $page-gutter * 2;

        @each $bp, $padding in $section-paddings {
            @include media-matching(md, $bp) {
                @include media($bp) {
                    padding-top: $padding * 2;
                    padding-bottom: $padding;
                }
            }
        }
    }

    &__image {
        img {
            display: block;
            width: 100%;
        }
    }

    @include media(md) {
        align-items: center;

        &__content {
            @extend %grid__col--5-md;
            order: -1;
        }

        &__image {
            @extend %grid__col--7-md;
        }
    }

    @include media(lg) {
        &__content {
            @extend %grid__col--4-lg;
            @extend %grid__col--margin-left-1-lg;
        }

        &__image {
            @extend %grid__col--6-lg;
            @extend %grid__col--margin-right-1-lg;
        }
    }
}
