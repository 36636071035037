@mixin grid--overflow($root, $breakpoint, $cols, $side, $gutter) {
    $value: get-map-value($page-side-offsets, $breakpoint);
    $width: calc(#{percentage($cols / $grid-columns)} + #{$value});
    flex: 0 0 $width;
    max-width: $width;

    // stylelint-disable plugin/selector-bem-pattern
    &#{$root}--odd {
        margin-left: -$value;
    }

    &#{$root}--even {
        margin-right: -$value;
    }
    // stylelint-enable plugin/selector-bem-pattern
}

.grid {
    $root: &;
    @extend %grid;
    @extend %animation;
    @include each-breakpoint($grid-vertical-paddings, (margin-top, margin-bottom), 'multiply', -1);

    &__col {
        @extend %grid__col;
        @extend %animation__item;
        @extend %animation__item--from-bottom;
        position: relative;
        @include each-breakpoint($grid-vertical-paddings, (margin-top, margin-bottom));

        &--header {
            margin-top: get-bp-value($grid-vertical-paddings, xs) / 2;
            margin-bottom: 0;
        }

        @each $bp, $padding in $breakpoints {
            $offset: get-bp-value($grid-up, $bp);
            $grid-padding: get-bp-value($grid-vertical-paddings, $bp);

            @if ($offset > 0) {
                @include media($bp) {
                    &--up {
                        margin-top: -$offset + $grid-padding;
                    }

                    &--up-double {
                        margin-top: -($offset * 2) + $grid-padding;
                    }

                    &--down {
                        margin-top: $offset - $grid-padding;
                    }

                    &--down-double {
                        margin-top: ($offset * 2) - $grid-padding;
                    }

                    &--down-triple {
                        margin-top: ($offset * 3) - $grid-padding;
                    }

                    &--cut-off {
                        margin-bottom: -($grid-padding * 2);
                    }

                    &--header {
                        margin-top: 0;
                        margin-bottom: $offset;
                    }
                }
            }
        }

        &--footer {
            margin-bottom: 0;
        }
    }

    &__image {
        display: block;
    }

    &__content {
        @include each-breakpoint($grid-vertical-paddings, (padding-top, padding-bottom), multiply, 1.5);
        text-align: left;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }

        &--shift {
            $padding: null;

            @each $bp, $size in $breakpoints {
                $newPadding: get-bp-value($grid-vertical-paddings, $bp);

                @include media-matching(md, $bp) {
                    @if ($newPadding != $padding) {
                        $padding: $newPadding;

                        @include media($bp) {
                            margin-top: $padding * 4;
                        }
                    }
                }
            }
        }
    }

    &--leading {
        margin-bottom: 0;
    }

    &--following {
        @include each-breakpoint($grid-vertical-paddings, margin-top);
    }

    &--bottom {
        align-items: flex-end;
    }

    @include media(xs, md) {
        &__col {
            &--combine-text {
                margin-top: get-map-value($grid-vertical-paddings, xs) * -1.5;
            }
        }
    }

    @include media(md) {
        &--reverse-order {
            flex-direction: row-reverse;
        }

        &__col {
            $item: &;
            $count: length($box-params);

            &--odd {
                margin-right: auto;
                text-align: left;
            }

            &--even {
                margin-left: auto;
                text-align: right;
            }

            &--spaced {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &--header {
                $width: percentage((($grid-columns / 2) - 1) / $grid-columns);
                flex: 0 0 $width;
                max-width: $width;
                margin-right: (100% - $width);
            }

            &--footer {
                display: none;
            }

            @each $type, $params in $box-params {
                $cols: nth($params, 1);
                $offset-left: nth($params, 2);
                $offset-right: nth($params, 3);
                $overflow: nth($params, 4);

                &--#{$type} {
                    @extend %grid__col--#{$cols}-md;

                    @if $offset-left > 0 {
                        @extend %grid__col--margin-left-#{$offset-left}-md;
                    }

                    @if $offset-right > 0 {
                        @extend %grid__col--margin-right-#{$offset-right}-md;
                    }

                    @if $overflow {
                        @each $bp, $gutter in $grid-gutters {
                            @include media($bp) {
                                @include grid--overflow($item, $bp, $cols, right, $gutter);
                            }
                        }
                    }
                }
            }

            // It's not the BEM way but there's no time to make a block from `&__col` only for this
            &--bravo {
                #{$root}__content {
                    @include each-breakpoint($page-side-offsets, padding-left);
                }
            }

            &--combine-text {
                margin-top: 20%;
                margin-left: percentage(-2 / $grid-columns);
            }
        }
    }

    @include media(md, lg) {
        &__col {
            &--foxtrot {
                margin-right: 0;
                margin-left: auto;
            }

            &--promo {
                margin-left: 0;
            }
        }
    }
}
