.button {
    $root: &;
    $button-padding: $page-gutter * 2;
    display: inline-block;
    vertical-align: top; // remove bottom margin
    max-width: 100%;
    padding: 0 $button-padding;
    overflow: hidden;
    border: 0;
    outline: 0;
    background: none;
    font: 500 1em/#{3} $font-headings-family;
    @include each-breakpoint($button-font-sizes, font-size);
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .333em;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition-duration: $transition-time; // stylelint-disable order/properties-order

    &:hover,
    &:focus {
        opacity: .5;
    }

    &__icon {
        vertical-align: middle;
        pointer-events: none;
        transition: transform $transition-time;

        &--before {
            margin: 0 ($button-padding / 2) 0 (-$button-padding / 2);
        }

        &--after {
            margin: 0 (-$button-padding / 2) 0 ($button-padding / 2);
        }
    }

    &__text {
        &--desktop {
            display: none;
        }
    }

    @mixin button($color-bg, $color-text) {
        @if ($color-text != null) {
            color: $color-text;
        }

        @if ($color-bg != null) {
            background: $color-bg;

            &:hover,
            &:focus {
                background: if(lightness($color-bg) > 50, darken($color-bg, 7.5%), lighten($color-bg, 5%));
            }
        }
    }

    @each $type, $params in $buttons-types {
        @if ($type == 'default') {
            @include button(nth($params, 1), nth($params, 2));
        } @else {
            &--#{$type} {
                @include button(nth($params, 1), nth($params, 2));
            }
        }
    }

    &--block,
    &--full {
        display: block;
        text-align: center;
    }

    &--full {
        width: 100%;
    }

    &--icon {
        padding: 0 .667em;
    }

    &--close {
        &:hover,
        &:focus {
            #{$root}__icon {
                transform: rotate(90deg);
            }
        }
    }

    @include media(xs, md) {
        &--icon-switch {
            padding: 0 .667em;

            #{$root}__text {
                display: none;
            }
        }

        &--block-mobile {
            display: block;
            text-align: center;
        }
    }

    @include media(md) {
        &--icon-switch {
            #{$root}__icon {
                display: none;
            }
        }
    }

    @include media(lg) {
        padding: 0 $page-gutter * 4;

        &--icon {
            padding: 0 .75em;
        }
    }

    @include media(xl) {
        &__text {
            &--mobile {
                display: none;
            }

            &--desktop {
                display: inline;
            }
        }

        &--icon {
            padding: .667em 1.333em;

            #{$root}__icon {
                width: 2em;
                height: 2em;
            }
        }
    }
}
