.label {
    $root: &;
    display: block;
    margin-bottom: $page-gutter * 2;
    font: 500 18px/#{$label-line-height} $title-font-family;
    color: $label-color;
    text-transform: uppercase;
    letter-spacing: .333em;

    &--heading {
        margin-bottom: $page-gutter;
    }

    &--secondary {
        @include each-breakpoint($label-secondary-sizes, font-size);
        font-weight: 500;
    }

    &--minor {
        @extend #{$root}--secondary;
        color: inherit;
    }

    &--text {
        font-size: 1rem;
    }
}
