.search-box {
    &__input-wrapper {
        position: relative;
        display: block;
        padding: ($page-gutter * 2.5) ($page-gutter * 3);
        background: $search-box-input-bg;
    }

    &__input {
        padding: $page-gutter;
    }

    &__icon {
        position: absolute;
        right: $page-gutter * 4;
        top: 50%;
        color: $search-box-icon-color;
        transform: translate(0, -50%);
    }

    &__result {
        padding: $page-gutter * 4;
        border-bottom: 1px solid $search-box-results-border;
        background: $search-box-results-bg;
        color: $search-box-results-color;
        transition: background-color $transition-time;
        cursor: pointer;

        &:hover {
            background: $search-box-results-bg-hover;
        }
    }

    &__result-name {
        margin: 0;
        font-size: 18px;
    }

    &__result-address {
        margin: 0;
        padding-left: $page-gutter * 3;
    }

    &__error {
        padding: ($page-gutter / 2) ($page-gutter * 4);
        background: $search-box-error-bg;
        color: $search-box-error-color;
    }
}
