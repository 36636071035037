%hyphen {
    $root: &;

    @include iterate-breakpoints {
        position: relative;
        padding-left: $hyphen-width + $page-gutter;

        &::after {
            content: '';
            position: absolute;
            top: $font-base-line-height / 2 * 1em;
            left: 0;
            margin-top: -($hyphen-height / 2);
            display: block;
            width: $hyphen-width;
            height: $hyphen-height;
            background: $hyphen-color;
        }

        $gutter: 0; // stylelint-disable-line order/order

        &--after {
            padding-left: 0;

            &::after {
                position: static;
                margin: ($page-gutter * 2) 0;
            }
        }

        &--invisible {
            &::after {
                visibility: hidden;
            }
        }

        @each $breakpoint, $width in $hyphen-wide-widths {
            $gutter: map_get($grid-gutters, $breakpoint) or $gutter;

            @include media($breakpoint) {
                &--wide {
                    padding-left: $width + $gutter;
                }

                &--wide,
                &--after {
                    &::after {
                        width: $width;
                    }
                }
            }
        }
    }
}
