.contact-details {
    position: relative;
    padding: $page-gutter * 4;
    background: $contact-details-bg;

    &__close {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__type {
        margin-bottom: 0;
    }

    &__hours {
        padding-left: $page-gutter * 3;
        margin-bottom: $page-gutter;
    }

    &__phone {
        margin-bottom: $page-gutter;
    }

    &__email {
        margin-bottom: $page-gutter;
    }

    &__buttons {
        display: flex;
        margin-top: $page-gutter * 2.5;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: $page-gutter * 2;
        padding: 0;
        width: $page-gutter * 3.5;
        border: 1px solid $contact-details-button-border;
    }
}
