// Similar to built-in `map-get` but allows "deep get" (deeper keys as next parameters)
// and warns if the key is not found
@function get-map-value($map, $keys...) {
    @each $key in $keys {
        $isValidKey: map-has-key($map, $key);

        @if ($isValidKey == false) {
            @warn "There is no element with key: '#{$key}'.";

            @return null;
        }

        $map: map-get($map, $key);
    }

    @return $map;
}

// Similar to `get-map-value` but falls back to smaller BP if there's no key found in map for given BP
@function get-bp-value($map, $bp) {
    $index: index(map-keys($breakpoints), $bp);

    @if $index == null or $index <= 0 {
        @return null;
    }

    $hasKey: map-has-key($map, $bp);

    @if ($hasKey == false) {
        @return get-bp-value($map, nth(map-keys($breakpoints), $index - 1));
    }

    @return map-get($map, $bp);
}

@function breakpoint($key) {
    @return get-map-value($breakpoints, $key) * 1px;
}

@mixin media($minWidth, $maxWidth: null) {
    @if type-of($minWidth) != number {
        $minWidth: breakpoint($minWidth);
    }

    @if ($minWidth != null) {
        @if ($maxWidth != null) {
            @if type-of($maxWidth) != number {
                $maxWidth: breakpoint($maxWidth);
            }

            @media (min-width: $minWidth) and (max-width: $maxWidth - 1px) {
                @content;
            }
        } @else {
            @if ($minWidth != 0) {
                @media (min-width: $minWidth) {
                    @content;
                }
            } @else {
                @content;
            }
        }
    } @else {
        @warn "Unfortunately, no value could be retrieved from '#{$minWidth}'." + " Please make sure it is defined in `$breakpoints` map.";
    }
}

@mixin media-matching($compareBp, $bp) {
    @if (breakpoint($bp) >= breakpoint($compareBp)) {
        @content;
    }
}

@mixin each-breakpoint($map, $properties, $operation: null, $operationValue: null) {
    @each $bp, $value in $map {
        $newValue: $value;

        @if ($operation == 'multiply') {
            $newValue: $value * $operationValue;
        } @else if ($operation == 'divide') {
            $newValue: $value / $operationValue;
        } @else if ($operation == 'add') {
            $newValue: $value + $operationValue;
        } @else if ($operation == 'subtract') {
            $newValue: $value - $operationValue;
        }

        @include media($bp) {
            @each $property in $properties {
                #{$property}: $newValue;
            }
        }
    }
}

@function media-prefix($breakpoint) {
    @if ($breakpoint == null or $breakpoint == xs) {
        @return null;
    }

    @return #{$breakpoint};
}

// This mixin returns new block name for each breakpoint defined in $breakpoints map.
// E.g. used inside %pleceholder block it will return %placeholder, %placeholder-sm, etc. for each breakpoint.
// Use it wisely (inside placeholders only) as it will return same code multiple times (once for breakpoint).
@mixin iterate-breakpoints {
    @each $breakpoint, $width in $breakpoints {
        @include media($breakpoint) {
            @if ($breakpoint == null or $breakpoint == xs) {
                @content;
            } @else {
                // stylelint-disable plugin/selector-bem-pattern
                &-#{$breakpoint} {
                    @content;
                }
                // stylelint-enable plugin/selector-bem-pattern
            }
        }
    }
}

@function grid-width($bp) {
    $breakpointWidth: get-bp-value($breakpoints, $bp);

    @if $breakpointWidth > 0 {
        @return get-bp-value($breakpoints, $bp) - (get-bp-value($page-side-paddings, $bp) * 2);
    } @else {
        @return 0;
    }
}

@function grid-column-width($bp) {
    @return grid-width($bp) / $grid-columns;
}

@function z-index($key) {
    @return get-map-value($z-index, $key);
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @return if(
        $index,
        str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace),
        $string
    );
}

@function svg-url($svg) {
    @if not str-index($svg, xmlns) {
        $svg: str-replace($svg, '<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
    }

    $encoded: '';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg) / $slice);

    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);

        $chunk: str-replace($chunk, '"', '\'');
        $chunk: str-replace($chunk, '%', '%25');
        $chunk: str-replace($chunk, '#', '%23');
        $chunk: str-replace($chunk, '{', '%7B');
        $chunk: str-replace($chunk, '}', '%7D');
        $chunk: str-replace($chunk, '<', '%3C');
        $chunk: str-replace($chunk, '>', '%3E');

        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }

    @return url("data:image/svg+xml,#{$encoded}");
}

@mixin inner-shadow($position, $size, $color) {
    @if ($position == "top") {
        box-shadow: inset 0 ($size * 1.5) $size (-$size) $color;
    } @else if ($position == "bottom") {
        box-shadow: inset 0 (-$size * 1.5) $size (-$size) $color;
    } @else if ($position == "left") {
        box-shadow: inset ($size * 1.5) 0 $size (-$size) $color;
    } @else if ($position == "right") {
        box-shadow: inset (-$size * 1.5) 0 $size (-$size) $color;
    } @else {
        @warn "Invalid `$position` value. Valid values are 'top', 'bottom', 'left' and 'right'.";
    }
}

@mixin scrim-gradient($startColor: $black, $direction: 'to bottom', $initialAlphaValue: 1) {
    $scrimCoordinates: (
        0: 1,
        19: .738,
        34: .541,
        47: .382,
        56.5: .278,
        65: .194,
        73: .126,
        80.2: .075,
        86.1: .042,
        91: .021,
        95.2: .008,
        98.2: .002,
        100: 0
    );

    $hue: hue($startColor);
    $saturation: saturation($startColor);
    $lightness: lightness($startColor);
    $stops: ();

    @each $colorStop, $alphaValue in $scrimCoordinates {
        $stop: hsla($hue, $saturation, $lightness, ($alphaValue * $initialAlphaValue)) percentage($colorStop / 100);
        $stops: append($stops, $stop, comma);
    }
    background: linear-gradient(unquote($direction), $stops);
}
