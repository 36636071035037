$plyr-border-radius: 0;

// Plyr settings
$plyr-bp-sm: breakpoint(sm);
$plyr-bp-md: breakpoint(md);
$plyr-bp-lg: breakpoint(lg);

$plyr-color-main: $player-audio-color-icons;

$plyr-font-family: $font-base-family;
$plyr-font-size-base: 13px;
$plyr-font-size-small: $plyr-font-size-base;
$plyr-font-size-large: $plyr-font-size-base;
$plyr-font-size-xlarge: $plyr-font-size-base;

$plyr-font-size-time: $plyr-font-size-small;
$plyr-font-size-badge: 9px;
$plyr-font-size-menu: $plyr-font-size-small;

$plyr-font-weight-regular: 300;
$plyr-font-weight-bold: 500;

$plyr-line-height: $font-base-line-height;

$plyr-range-track-height: 2px;
$plyr-range-thumb-height: 14px;
$plyr-range-thumb-bg: transparent;
$plyr-range-thumb-border: none;
$plyr-range-thumb-shadow: none;

$plyr-control-icon-size: 24px;
$plyr-control-icon-size-large: $plyr-control-icon-size * 1.5;
$plyr-control-spacing: $page-gutter * 2;
$plyr-control-radius: $plyr-border-radius;

$plyr-audio-controls-bg: $player-audio-color-bg;
$plyr-audio-control-color: $plyr-color-main;
$plyr-audio-control-color-hover: $player-audio-color-bg;
$plyr-audio-control-bg-hover: $plyr-color-main;


$plyr-tooltip-bg: rgba($player-audio-color-bg, .85);
$plyr-tooltip-color: $player-audio-color-text;
$plyr-tooltip-padding: $page-gutter / 2;
$plyr-tooltip-arrow-size: 4px;
$plyr-tooltip-radius: $plyr-border-radius;
$plyr-tooltip-shadow: 0 .25em .75em rgba($black, .15);

$plyr-tab-focus-default-color: $player-audio-color-bg;

@import "plyr/src/sass/plyr";

.plyr {
    $root: &;

    &__control {
        &--overlaid {
            padding: $plyr-control-spacing;
            box-shadow: 0 0 0 .825em $play-color-outline;
            background: $play-color-bg !important; // stylelint-disable-line declaration-no-important
            color: $play-color-text !important; // stylelint-disable-line declaration-no-important
        }
    }

    &--audio {
        #{$root} {
            &__controls {
                padding: 0;
            }

            &__control {
                padding: $page-gutter;
                border-left: 1px solid $player-audio-color-border;
                border-right: 1px solid $player-audio-color-border;

                &:first-child {
                    border-left: 0;
                }

                &:last-child {
                    border-right: 0;
                }

                svg {
                    width: $plyr-control-icon-size * 1.5;
                    height: $plyr-control-icon-size * 1.5;
                }
            }

            &__time,
            &__tooltip {
                color: $player-audio-color-text;
            }
        }
    }

    @include media(md) {
        &--audio {
            #{$root} {
                &__control {
                    padding: $page-gutter * 1.75;
                }
            }
        }
    }
}
