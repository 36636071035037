.nav {
    $root: &;
    flex-direction: column;

    &__container {
        max-width: none;
    }

    &__inner,
    &__submenu {
        border-top: 1px solid $nav-color-border;
    }

    &__list {
        display: block;
        font: 500 12px/1.5 $font-headings-family;
    }

    &__item {
        display: block;
        text-transform: uppercase;
    }

    &__link {
        @extend %border-on-hover;
        display: inline-block;
        padding: $page-gutter 0;
        color: inherit;
        text-decoration: none;
    }

    @include media(xs, xl) {
        &__inner {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }

        &__list {
            padding: ($page-gutter * 2) 0;

            &--secondary {
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                margin: 0 #{-$page-gutter};

                #{$root}__link {
                    padding: $page-gutter;
                }
            }
        }

        &__menu {
            margin-bottom: auto;
        }
    }

    @include media(xl) {
        &__container {
            max-width: $page-max-width - (get-bp-value($page-side-paddings, xxl) * 2);
            margin-top: 0;
            padding-left: 0;
            padding-right: 0;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            font-size: 13px;

            &--secondary {
                display: flex;
                justify-content: flex-end;
                margin-right: -2em;
            }
        }

        &__item {
            margin: 0 2em;
        }

        &__link {
            padding: 0;
            line-height: $header-nav-item-height;
        }

        &__submenu {
            display: none;
            border-top: 0;

            #{$root}__container {
                border-bottom: 0;
            }

            &.-is-active {
                display: block;
            }
        }

        &.-is-submenu-active {
            #{$root}__menu {
                display: none;
            }
        }
    }
}
