.section {
    $root: &;
    display: block;

    &__container {
        position: relative;
        z-index: 0;
    }

    // stylelint-disable plugin/selector-bem-pattern
    @each $bp, $value in $breakpoints {
        $padding: map_get($section-paddings, $bp);

        @include media($bp) {
            // Modify section paddings on given breakpoints
            @if ($padding) {
                &__inner {
                    padding: $padding 0;
                }

                &--condensed {
                    #{$root}__inner {
                        padding: ($padding * 2 / 3) 0;
                    }
                }

                &--last,
                &--leading {
                    #{$root}__inner {
                        padding-bottom: $padding * 2;
                    }
                }

                &--following,
                &--up {
                    margin-top: -$padding;
                }

                &--overlap {
                    #{$root}__inner {
                        padding-bottom: $padding * 4;
                    }

                    + #{$root} {
                        #{$root}__container {
                            margin-top: -$padding * 4;

                            &::before {
                                margin-top: $padding * 4;
                            }
                        }
                    }
                }

                &--cut-off {
                    #{$root}__container {
                        margin-bottom: -($padding * 2);
                    }

                    + #{$root} {
                        #{$root}__container {
                            margin-top: $padding;
                        }

                        &--cutable {
                            #{$root}__inner {
                                padding-top: 0;
                            }
                        }
                    }
                }

                &--shift {
                    #{$root}__container {
                        margin-top: -$padding * 2;
                    }
                }

                @include media-matching(md, $bp) {
                    &--following-desktop {
                        margin-top: -$padding;
                    }
                }
            }

            // Modify borders on given breakpoints
            @include media-matching(md, $bp) {
                $side-offset: get-bp-value($page-side-paddings, $bp);
                $padding: if($padding, $padding, get-bp-value($section-paddings, $bp));

                &--border {
                    #{$root}__container {
                        &::before {
                            top: -$padding;
                            bottom: -$padding;
                            left: $side-offset;
                            right: $side-offset;
                        }
                    }

                    &#{$root}--cut-off {
                        #{$root}__container {
                            &::before {
                                bottom: $padding;
                            }
                        }
                    }

                    &#{$root}--condensed {
                        #{$root}__container {
                            &::before {
                                top: -$padding * (2 / 3);
                                bottom: -$padding * (2 / 3);
                            }
                        }
                    }

                    &#{$root}--shift {
                        #{$root}__container {
                            &::before {
                                top: $padding;
                            }
                        }
                    }
                }

                &--border-narrow {
                    #{$root}__container {
                        &::before {
                            right: $side-offset + grid-column-width($bp);
                        }
                    }
                }

                &--border-narrow-reverse {
                    #{$root}__container {
                        &::before {
                            left: $side-offset + grid-column-width($bp);
                        }
                    }
                }

                &--border-narrow-both {
                    #{$root}__container {
                        &::before {
                            left: $side-offset + grid-column-width($bp);
                            right: $side-offset + grid-column-width($bp);
                        }
                    }
                }

                &--border-narrower {
                    #{$root}__container {
                        &::before {
                            right: $side-offset + (grid-column-width($bp) * 2);
                        }
                    }
                }

                &--border-start {
                    #{$root}__container {
                        &::before {
                            top: $padding * get-bp-value($section-border-start-offset-factor, $bp);
                        }
                    }
                }

                &--border-start-up {
                    #{$root}__container {
                        &::before {
                            top: $padding * get-bp-value($section-border-start-up-offset-factor, $bp);
                        }
                    }
                }

                &--border-end,
                &--border-end-up {
                    #{$root}__container {
                        padding-bottom: $padding;

                        &::before {
                            bottom: 0;
                        }
                    }

                    &#{$root}--cut-off {
                        #{$root}__container {
                            margin-bottom: -($padding * 3);

                            &::before {
                                bottom: $padding * 3;
                            }
                        }
                    }
                }

                &--border-end-up {
                    #{$root}__container {
                        &::before {
                            bottom: $padding * 2;
                        }
                    }
                }
            }
        }
    }
    // stylelint-enable plugin/selector-bem-pattern

    &--fluid {
        #{$root}__container {
            max-width: $page-max-width;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--heading,
    &--following {
        #{$root}__inner {
            padding-top: 0;
        }
    }

    &--low {
        #{$root}__inner {
            padding-bottom: 0;
        }
    }

    &--heading {
        #{$root}__container {
            z-index: 3;
        }
    }

    &--leading {
        #{$root}__container {
            z-index: 2;
        }
    }

    &--fullpage {
        #{$root}__inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 100vh;
            @include each-breakpoint($header-heights, margin-top, 'multiply', -1); // to reset .wrapper's top padding
            @include each-breakpoint($header-heights, (padding-top, padding-bottom));
        }
    }

    @mixin section--type($color-bg, $color-text) {
        background: $color-bg;
        color: $color-text;
    }

    @each $type, $colors in $section-colors {
        @if $type == 'default' {
            @include section--type(nth($colors, 1), nth($colors, 2));
        } @else {
            &--#{$type} {
                @include section--type(nth($colors, 1), nth($colors, 2));
            }
        }
    }

    &--error {
        @extend #{$root}--fullpage;
        @extend #{$root}--odd;
        background-image: url('../images/background/error-bg.jpg');
        background-position: center top;
        background-size: cover;
    }

    @include media(xs, md) {
        &--hide-mobile {
            display: none;
        }
    }

    @include media(md) {
        &--following-desktop {
            #{$root}__inner {
                padding-top: 0;
            }
        }

        &--border {
            #{$root}__container {
                display: table; // normalize container position when elements with negative margins are inside (e.g. `.grid`)
                width: 100%;

                &::before {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    border: 1px solid $section-color-border;
                    border-top-width: 0;
                    border-bottom-width: 0;
                }
            }
        }

        &--border-closed,
        &--border-start,
        &--border-start-up {
            #{$root}__container {
                &::before {
                    border-top-width: 1px;
                }
            }
        }

        &--border-closed,
        &--border-end,
        &--border-end-up {
            #{$root}__container {
                &::before {
                    border-bottom-width: 1px;
                }
            }
        }

        &--border-edge {
            #{$root}__container {
                &::before {
                    right: 0;
                }
            }
        }
    }
}
