.gallery {
    $root: &;
    background: $gallery-color-border;

    &__outer {
        @extend %grid;
    }

    &__inner {
        @extend %grid__col;
        @extend %grid__col--10;
        @extend %grid__col--margin-1;
        padding: 0; // padding is set on &__image for correct width background
        display: flex;
        flex-direction: column;
        min-height: $gallery-min-height;
        max-height: 100vh;
    }

    // !importants are set to overwrite default tiny-slider plugin styles

    &__list {
        display: flex;
        overflow-y: auto;
    }

    &__item {
        display: flex !important; // stylelint-disable-line declaration-no-important
        flex-shrink: 0;
        width: 100%;
    }

    &__image {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        background: $gallery-color-bg;
        color: $gallery-color-text;

        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            transition: opacity $transition-time * 2;
        }
    }

    &__image,
    &__header,
    &__footer {
        @include each-breakpoint($grid-gutters, (margin-left, margin-right), 'divide', 2);
    }

    &__header,
    &__footer {
        @include each-breakpoint($grid-gutters, (margin-top, margin-bottom));
        text-align: right;
    }

    &__header {
        margin-top: 0;
    }

    &__footer {
        display: none;
        margin-bottom: 0;
    }

    &__button {
        transition: opacity $transition-time;

        &.-is-disabled {
            outline: none;
            opacity: .5;
            cursor: default;
        }
    }

    &.-is-init {
        overflow: hidden;

        #{$root} {
            &__list {
                cursor: move;
            }

            &__item {
                user-select: none;
            }

            &__footer {
                display: block;
            }
        }
    }

    // It's not proper BEM as the class names comes from external library
    // stylelint-disable
    .tns-ovh {
        overflow: visible;
    }

    // force slider plugin wrappers to inherit height
    .tns-outer,
    .tns-ovh,
    .tns-inner {
        display: flex;
        flex-direction: column;
        min-height: 0;
    }
    // stylelint-enable
}
