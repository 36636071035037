.tile {
    $root: &;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-width: 1px; // IE11
    outline: 0;
    background: transparent;
    color: inherit;
    text-align: left;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;


    &__title,
    &__lead {
        margin: ($page-gutter * 2) 0;
    }

    &__title {
        line-height: 1.285;
    }

    &__lead {
        font: 300 14px/1.333 $font-base-family;
    }

    &__image-wrapper {
        overflow: hidden;
    }

    &__image {
        display: block;
        width: 100%;
        transform: scale(1);
        transition: transform $transition-time;
    }

    &__link {
        margin-top: auto;
    }

    &__date {
        margin-top: auto;
    }

    &__badge {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }

    &:hover,
    &:focus {
        #{$root}__image {
            transform: scale($image-hover-scale);
        }
    }

    @include media(lg) {
        &__lead {
            font-size: 13px;
        }
    }
}
