.glyphs {
    $root: &;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $page-gutter * 2;

    &__item {
        width: percentage(1 / 3);
        margin-bottom: $page-gutter * 2;
    }
}
