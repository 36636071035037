.image-and-text {
    $root: &;
    @extend %grid;
    @extend %animation;
    align-items: stretch;

    &__col {
        @extend %grid__col;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
    }

    &__image {
        @extend %animation__item;
        @extend %animation__item--from-bottom;
        display: block;
        margin: 0 auto;

        &--extra {
            margin-top: auto;
        }

        &--bravo {
            @each $bp, $value in $breakpoints {
                @include media-matching(md, $bp) {
                    @include media($bp) {
                        $offset: get-bp-value($page-side-offsets, $bp);
                        $totalOffset: $offset + grid-column-width($bp) + 2px; // 2px comes probably from border
                        width: calc(100% + #{$totalOffset});
                    }
                }
            }
        }
    }

    &__footer {
        margin-top: $page-gutter * 2;
    }

    &__title {
        margin-bottom: .333em;
        color: $image-and-text-title-color;
    }

    @each $bp, $padding in $section-paddings {
        margin-top: -$padding;

        @include media($bp) {
            &__text {
                margin-top: $padding * 2;
            }

            &__image {
                &--extra {
                    padding-top: $padding;
                }
            }
        }
    }

    @include media(md) {
        &__text {
            width: 100%;
        }

        &__image {
            margin-left: 0;
            margin-right: 0;

            &--bravo {
                max-width: none;
            }
        }

        &__col {
            @extend %grid__col--5-md;
            align-items: flex-end;

            &--content {
                @extend %grid__col--margin-1-md;
                align-items: flex-start;
            }
        }

        &--odd {
            flex-direction: row-reverse;

            #{$root}__col {
                align-items: flex-start;

                &--content {
                    align-items: flex-end;
                }
            }
        }
    }

    @include media(lg) {
        &__footer {
            margin-top: $page-gutter * 3;
        }
    }
}
