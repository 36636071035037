.form-row {
    $root: &;
    margin-bottom: $page-gutter * 3;

    &__label {
        display: block;
        margin-bottom: $page-gutter / 2;
        font-size: 13px;
        cursor: pointer;

        &--required {
            &::after {
                content: '*';
                position: relative;
                right: -2px;
                top: -2px;
            }
        }
    }

    &__error {
        padding-left: $page-gutter;
        margin-top: $page-gutter / 2;
        color: $input-color-error;
        font-size: 11px;
    }

    &__disclaimer {
        padding: .375em 0 .375em ($page-gutter * 3);
        margin: 0;
        color: $form-disclaimer-color;
        font: 300 1rem/1.5 $font-base-family;
    }

    &--submit {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
    }

    &--minor {
        margin-bottom: $page-gutter;

        #{$root}__disclaimer {
            padding: 0;
            font-size: inherit;
            color: inherit;
        }
    }

    @include media(xs, lg) {
        &--minor {
            #{$root}__label {
                font-size: 10px;
            }
        }
    }
}
