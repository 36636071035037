.locator {
    position: relative;
    padding-bottom: $page-gutter * 3;
    background: $locator-bg;

    &__map {
        height: 32 * $page-gutter;
    }

    &__search-box-container {
        position: relative;
        margin: -($page-gutter * 11) auto 0;
        pointer-events: none;
    }

    &__search-box {
        pointer-events: auto;
    }

    @include media(md) {
        &__map {
            height: 52 * $page-gutter;
        }
    }

    @include media(xl) {
        &__map {
            height: 70 * $page-gutter;
        }

        &__search-box-container {
            position: absolute;
            top: $page-gutter * 17;
            display: flex;
            justify-content: flex-end;
            margin: 0;
        }

        &__search-box {
            width: $page-gutter * 41;
        }
    }
}
