.grid-content {
    $root: &;
    @extend %grid;
    @include each-breakpoint($grid-content-font-sizes, font-size);

    &__outer {
        @extend %grid__col;
    }

    &__label {
        margin-bottom: 1.5em;
        font-weight: 500;
        font-size: inherit;
    }

    &__text {
        line-height: $grid-content-line-height;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__footer {
        margin: ($page-gutter * 2) 0 ($page-gutter * 4);
    }

    @include media(md) {
        &:not(&--simple) {
            #{$root}__outer {
                @extend %grid__col--10-md;
                @extend %grid__col--margin-left-2-md;
            }
        }
    }

    @include media(lg) {
        &__footer {
            margin-top: $page-gutter * 3;
        }

        &--marked {
            #{$root} {
                &__inner {
                    @extend %hyphen-lg;
                    @extend %hyphen-lg--after;
                }

                &__text:last-child {
                    padding-bottom: $page-gutter * 2;
                }
            }
        }
    }
}
