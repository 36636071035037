.mega-input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    border-bottom: 1px solid $mega-input-border;
    background: transparent;
    color: $mega-input-color;
    font: 200 18px $font-headings-family;
    transition: border-color $transition-time;

    &:focus {
        outline: none;
        border-bottom-color: $mega-input-focus-border;
    }

    @include media(md) {
        padding: $page-gutter;
    }
}
