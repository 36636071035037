.article {
    display: block;

    &__section {
        @each $bp, $padding in $section-paddings {
            &:not(:first-child) {
                padding-top: $padding / 3;
            }

            &:not(:last-child) {
                padding-bottom: $padding / 3;
            }
        }
    }
}
