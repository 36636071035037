.tabs {
    $root: &;
    color: inherit;

    &__inner {
        position: relative;
        z-index: 1;

        @each $bp, $offset in $section-paddings {
            @include media($bp) {
                margin-bottom: -$offset;
                padding-bottom: $offset;
            }
        }
    }

    // make margin-top on &__content working properly
    &__container {
        display: flex;
        flex-direction: column;
    }

    // Only for extends not direct usage
    &__border {
        position: relative;
        z-index: 0;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: -1;
            margin: 0 -1px;
            @include each-breakpoint($page-side-paddings, (left, right), 'multiply', -1);
            border: 1px solid $tabs-color-border;
            pointer-events: none;
        }
    }

    &__content {
        @extend #{$root}__border;
        padding-bottom: $page-gutter * 2;
    }

    &__menu {
        padding-top: $page-gutter;

        &--secondary {
            @extend #{$root}__border;

            &::before {
                z-index: 2;
                border-bottom: 0;
            }
        }
    }

    &--odd {
        #{$root}__inner {
            background: nth(get-map-value($section-colors, 'odd'), 1);
            color: nth(get-map-value($section-colors, 'odd'), 2);
        }
    }

    &--content-open {
        #{$root}__content {
            &::before {
                bottom: auto;
                border-bottom: 0;
            }
        }
    }

    &--content-infinite {
        #{$root}__content {
            &::before {
                border-bottom: 0;
                @include each-breakpoint($section-paddings, bottom, 'multiply', -1);
            }
        }
    }

    &__tools {
        margin-top: $page-gutter;
        text-align: right;
    }

    @each $bp, $offset in $tabs-content-offset {
        @include media($bp) {
            &__content {
                margin-top: -$offset;
                padding-top: $offset / 2;
            }

            &__menu-wrapper {
                padding-bottom: $offset;
            }

            &--content-open {
                #{$root}__content {
                    &::before {
                        height: $offset;
                    }
                }
            }
        }
    }

    &.-is-dynamic {
        #{$root}__content {
            display: none;

            &.-is-active {
                display: block;
            }
        }

        &:not(.-is-init) {
            #{$root}__content:first-child {
                display: block;
            }
        }
    }

    @include media(md) {
        &__border {
            &::before {
                right: 0;
                margin-right: percentage(1 / $grid-columns);
            }
        }

        &__content {
            &::before {
                bottom: $page-gutter * 6;
            }
        }

        &__tools {
            display: none;
        }
    }

    @include media(lg) {
        &__content {
            &::before {
                bottom: $page-gutter * 9;
            }
        }

        &__menu {
            &--secondary {
                padding-top: $page-gutter * 1.5;
            }
        }
    }
}
