.carousel {
    $root: &;
    @extend %animation;
    display: block;

    &__outer {
        position: relative;
        margin: 0 #{-$page-gutter}; // to compensate &__item padding
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 100%;
            height: 100%;
            width: 100%;
            background: var(--color-bg);
        }
    }

    // !importants are set to overwrite default tiny-slider plugin styles

    &__inner {
        overflow: visible;
        padding-right: get-map-value($carousel-edge-paddings, xs);
    }

    &__content {
        display: flex !important; // stylelint-disable-line declaration-no-important
    }

    &__label {
        text-align: center;

        &--extra {
            text-align: left;
            color: $carousel-label-extra-color;
        }
    }

    &__item {
        @extend %animation__item;
        @extend %animation__item--from-bottom;
        flex-grow: 1;
        display: inline-block;
        min-width: 1px; // IE11
        margin: 0 !important; // stylelint-disable-line declaration-no-important
        padding: 0 $page-gutter !important; // stylelint-disable-line declaration-no-important
    }

    &__footer {
        display: none;
    }

    &.-is-init {
        overflow: visible;

        #{$root} {
            &__outer {
                overflow: visible;
            }

            &__content,
            &__footer {
                display: block;
            }
        }
    }

    &.-is-fitting {
        #{$root} {
            // Make all items equal height
            &__content {
                align-items: stretch;
            }

            &__footer {
                display: none;
            }
        }
    }

    @include media(sm) {
        &__inner {
            padding-right: 0;
        }

        &__item {
            flex-basis: 50%;
        }
    }

    @include media(md) {
        &__item {
            flex-basis: 33.333%;
        }
    }

    @include media(lg) {
        &__item {
            flex-basis: 25%;
        }
    }

    // Due to specificity issue it must go after @media
    // stylelint-disable order/order
    &:not(.-is-fitting) {
        #{$root}__inner {
            @include each-breakpoint($carousel-edge-paddings, padding-right);
        }
    }
    // stylelint-enable order/order

    // It's not proper BEM as the class names comes from external library
    // stylelint-disable
    .tns-inner {
        @extend #{$root}__inner;
    }

    .tns-ovh {
        overflow: visible;
    }
    // stylelint-enable
}
