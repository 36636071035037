.timeline {
    &__item {
        @include each-breakpoint($section-paddings, (margin-top, margin-bottom));

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include media(md) {
        margin: ($page-gutter * 2) 0;
    }

    @include media(xl) {
        margin-top: 0;
    }
}
