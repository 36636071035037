.badge {
    $root: &;
    display: inline-block;
    max-width: 100%;
    padding: .825em 2.125em;
    overflow: hidden;
    background: get-map-value($badge-colors, 'alpha', 'background');
    color: get-map-value($badge-colors, 'alpha', 'color');
    font: 500 .9285rem/1.5 $font-headings-family;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;

    @each $color, $values in $badge-colors {
        &--color-#{$color} { // stylelint-disable-line plugin/selector-bem-pattern
            background: get-map-value($values, 'background');
            color: get-map-value($values, 'color');
        }
    }

    &--vertical {
        transform: rotate(90deg) translateX(100%);
        transform-origin: top right;
    }

    &--circle {
        display: flex;
        align-items: center;
        width: 11.333em;
        height: 11.333em;
        margin: $page-gutter * 2;
        padding: .825em;
        border-radius: 50%;
        white-space: normal;

        @each $color, $values in $badge-colors {
            &#{$root}--color-#{$color} { // stylelint-disable-line plugin/selector-bem-pattern
                $color-bg: get-map-value($values, 'background');
                background: linear-gradient(135deg, lighten($color-bg, 33.333%) -10%, $color-bg 50%, darken($color-bg, 33.333%) 110%);
            }
        }
    }
}
