.input-text {
    display: block;
    width: 100%;
    padding: .53em $page-gutter;
    background: $input-color-bg;
    border: none;
    border-bottom: 1px solid $input-color-border;
    color: $input-color-text;
    font: 300 18px $font-base-family;

    &--error {
        border-color: $input-color-error;
    }

    &--newsletter {
        padding: 0 .75em;
        background: $input-newsletter-color-bg;
        border-color: $input-newsletter-color-border;
        color: $input-newsletter-color-text;
        font-size: 12px;
        line-height: 3;
    }

    @include media(lg) {
        &--newsletter {
            font-size: 18px;
            line-height: 2.333;
        }
    }
}
