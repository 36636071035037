.definition {
    display: block;

    @include media(md) {
        &__label,
        &__content {
            @extend %hyphen-md;
            @extend %hyphen-md--wide;
        }

        &__content {
            @extend %hyphen-md--invisible;
        }
    }
}
