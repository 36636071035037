.jumbotron {
    position: relative;
    z-index: z-index('jumbotron');
    display: block;
    max-width: $page-max-width;
    margin: 0 auto;
    @include each-breakpoint($header-heights, margin-top, 'multiply', -1); // reset site top padding

    &--higher {
        z-index: z-index('jumbotron-over-the-main'); // jumbo must be above the main
    }

    @include media(xl) {
        // Shadow beneath header
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: get-bp-value($header-heights, xl) * 2.25;
            @include scrim-gradient($header-color-bg, 'to bottom', .85);
            pointer-events: none;
        }
    }
}
