.rules {
    $root: &;
    @extend %grid;

    &__content {
        @extend %grid__col;
    }

    &__title,
    &__label {
        margin: 1.5em 0;
    }

    @include media(lg) {
        &__content {
            @extend %grid__col--8-lg;
        }
    }
}
