@mixin logo-image--simplify($root) {
    transform: scale(1.4) translateY(15%); // to compensate hidden date
    transform-origin: center;

    // stylelint-disable-next-line plugin/selector-bem-pattern
    #{$root}__date {
        display: none;
    }
}

.logo-image {
    $root: &;
    display: block;
    transition-duration: $transition-time;

    &:hover,
    &:focus {
        opacity: .5;
    }

    &--simplify {
        #{$root}__name {
            fill: currentColor!important;
        }

        &#{$root}--advanced {
            @include logo-image--simplify($root);
        }
    }

    @include media(xs, md) {
        &--advanced {
            @include logo-image--simplify($root);
        }
    }
}
