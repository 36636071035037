// stylelint-disable
@mixin quote-gutters($bp) {
    $root: &;
    $column-width: grid-column-width($bp);

    @include media-matching(xl, $bp) {
        @include media($bp) {
            margin-left: $column-width * 2;
            margin-right: $column-width * 2;
        }
    }

    #{$root}__icon {
        left: $column-width / 2;
    }

    #{$root}__content {
        margin-left: ($column-width * 1.5) - 1px;
        margin-right: $column-width;
    }

    &--with-photo {
        $image-width: $column-width * 4;
        margin-left: 0;
        margin-right: $column-width;

        #{$root}__image {
            width: $image-width;
        }
    }
}
// stylelint-enable

.quote {
    $root: &;
    $image-offset: $page-gutter * 3;
    @extend %animation;
    position: relative;
    border: 1px solid $quote-border-color;

    @each $bp, $padding in $page-side-paddings {
        @include media-matching($bp, sm) {
            @include media($bp, md) {
                margin-left: #{-$padding};
                margin-right: #{-$padding};
                padding: 0 $padding;
            }
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        padding-left: nth(get-bp-value($quote-icon-sizes, xs), 1) + $page-gutter;
    }

    &__content {
        padding: ($page-gutter * 3) 0;
    }

    &__icon {
        position: absolute;
        top: -$page-gutter;
        left: $page-gutter;
        color: $quote-icon-color;

        @each $breakpoint, $size in $quote-icon-sizes {
            @include media($breakpoint) {
                width: nth($size, 1);
                height: nth($size, 2);
            }
        }

        svg {
            @extend %svg;
        }
    }

    &__cite {
        margin: -.5em 0 .5em;
        @include each-breakpoint($quote-font-sizes, font-size);
    }

    &__text {
        margin: .5em 0;
        font-size: inherit;
    }

    &__link {
        margin-top: $page-gutter * 2;
    }

    &__signature {
        display: flex;
        justify-content: flex-end;
        margin-top: $page-gutter;

        svg {
            @extend %svg;

            @each $breakpoint, $size in $quote-signature-size {
                @include media($breakpoint) {
                    width: nth($size, 1);
                    height: nth($size, 2);
                }
            }
        }
    }

    &__image {
        @extend %animation__item;
        @extend %animation__item--from-bottom;
        margin-bottom: -$image-offset;
    }

    &--with-photo {
        margin-bottom: $image-offset;
    }

    @include media(md) {
        &__inner {
            flex-direction: row;
            align-items: center;
            padding-left: 0;
        }

        &__content {
            flex-grow: 1;
        }

        &__image {
            flex-shrink: 0;
            align-self: flex-start;
            margin: -$image-offset -1px;
            text-align: right;
        }

        @include quote-gutters(md);

        &--with-photo {
            margin: $image-offset 0 0;
        }
    }

    @include media(lg) {
        @include quote-gutters(lg);
    }

    @include media(xl) {
        &__image {
            margin: -($image-offset * 2) -1px;
        }

        @include quote-gutters(xl);

        &--with-photo {
            margin: ($image-offset * 2) 0;
        }
    }
}
