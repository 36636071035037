.tabs-menu {
    $root: &;

    &__outer {
        @extend %grid;
    }

    &__inner {
        @extend %grid__col;
    }

    @include media(md) {
        &--on-grid {
            #{$root} {
                &__inner {
                    padding-right: 50%;
                }

                &__list {
                    margin-right: 0;
                }
            }
        }

        &:not(&--on-grid) {
            #{$root}__inner {
                @extend %grid__col--11-md;
            }
        }
    }
}
