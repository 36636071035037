.simplest-teaser {
    $root: &;
    display: block;
    @include each-breakpoint($page-side-offsets, (margin-left, margin-right), 'multiply', -1);
    @include each-breakpoint($page-side-offsets, (padding-left, padding-right));
    background: $simplest-teaser-color-bg;
    color: $simplest-teaser-color-text;
    text-decoration: none;

    &__outer {
        @include each-breakpoint($simplest-teaser-title-shifts, margin-top, 'multiply', 2);
    }

    &__title {
        @include each-breakpoint($simplest-teaser-title-shifts, margin-top, 'multiply', -1);
    }

    &__inner {
        @extend %grid;
    }

    &__header,
    &__content {
        @extend %grid__col;
    }

    &__content {
        padding: ($page-gutter * 2) 0;
    }

    @include media(md) {
        &__header,
        &__content {
            padding-top: $page-gutter * 2.5;
            padding-bottom: $page-gutter * 2.5;
        }

        &__header {
            @extend %grid__col--5-md;
            padding-top: 0;
        }

        &__content {
            @extend %grid__col--7-md;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
        }
    }

    @include media(xl) {
        &__header,
        &__content {
            padding-top: $page-gutter * 3.5;
            padding-bottom: $page-gutter * 3.5;
        }

        &__header {
            padding-top: 0;
        }
    }
}
