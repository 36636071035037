.slider {
    $root: &;
    @extend %page-max-width;
    position: relative;

    &__list {
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        transition-timing-function: ease !important; // stylelint-disable-line declaration-no-important
    }

    &__item {
        display: none;
        width: 100%;
        transform: translateZ(0);

        &:first-child {
            display: block;
        }
    }

    &__nav {
        position: absolute;
        @include each-breakpoint($banner-image-heights, top, 'divide', 2);

        &--prev {
            left: 0;
        }

        &--next {
            right: 0;
        }
    }

    &__scroll {
        display: none;
    }

    &--in-header {
        margin-top: 0;
    }

    &.-is-init {
        #{$root}__item {
            display: inline-block;
        }
    }

    @include media(xl) {
        &__scroll {
            position: absolute;
            bottom: 0;
            right: 0;
            display: block;
            padding-right: get-map-value($page-side-offsets, xl);
        }

        &__nav {
            @extend %hyphen-xl;

            &--prev {
                transform-origin: left top;
                transform: rotate(-90deg);
            }

            &--next {
                transform-origin: right top;
                transform: rotate(90deg);
            }
        }

        &.-is-init {
            #{$root} {
                &__list {
                    user-select: none;
                }
            }
        }
    }
}
