.footer-newsletter {
    display: block;

    &__text {
        margin: $page-gutter 0;
        opacity: .75;
    }

    &__form {
        display: flex;
        width: 100%;
        max-width: $footer-form-max-width;
        font-size: 13px;
    }

    &__input {
        padding: .75em 1em;
        width: 100%;
        outline: 0;
        background: transparent;
        border: 1px solid $footer-input-border;
        color: $footer-input-color;
        font-size: inherit;

        &::placeholder {
            color: $footer-input-color;
        }
    }

    &__button {
        padding: .75em 1.5em;
        border: 1px solid $footer-input-border;
        border-left: 0;
        outline: 0;
        background: $footer-button-background;
        text-transform: uppercase;
        font-size: inherit;
        color: $footer-button-color;
        transition-duration: $transition-time;
        cursor: pointer;

        &:hover,
        &:focus {
            background: $newsletter-button-hover-bg;
            color: $newsletter-button-hover-text;
        }
    }

    &__disclaimer {
        margin-top: $page-gutter * 2;
        font-size: 10px;
    }

    @include media(xl) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__text {
            margin: ($page-gutter * 2) 0;
        }

        &__input {
            padding: .75em 2em;
        }

        &__button {
            padding: .75em 3em;
        }
    }
}
