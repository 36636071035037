.image-described {
    $root: &;
    @extend %grid;
    align-items: center;

    &__image,
    &__content {
        @extend %grid__col;
    }

    &__image {
        img {
            display: block;
            width: 100%;
        }
    }

    &__footer {
        margin-top: $page-gutter * 2;
    }

    &--vertical {
        #{$root} {
            &__content {
                order: -1;
            }

            &__text {
                @extend %hyphen;
                @extend %hyphen--after;
            }
        }
    }

    @include media(md) {
        &__image,
        &__content {
            @extend %grid__col--6-md;
        }

        &--horizontal {
            #{$root} {
                &__image {
                    // side offset
                    @each $bp, $offset in $page-side-paddings {
                        @include media-matching(md, $bp) {
                            @include media($bp) {
                                $offset: get-map-value($page-side-paddings, $bp);
                                $width: calc(#{percentage(6 / $grid-columns)} + #{$offset});
                                flex: 0 0 $width;
                                max-width: $width;
                                margin-left: -$offset;
                            }
                        }
                    }
                }

                &__content {
                    @extend %grid__col--5-md;
                    @extend %grid__col--margin-left-1-md;
                }
            }
        }

        &--vertical {
            #{$root} {
                &__content {
                    @extend %grid__col--5-md;
                }

                &__image {
                    @extend %grid__col--margin-left-1-md;
                }
            }
        }
    }

    @include media(lg) {
        &__footer {
            margin-top: $page-gutter * 3;
        }

        &--vertical {
            #{$root} {
                &__image {
                    @extend %grid__col--4-lg;
                    @extend %grid__col--margin-right-1-lg;
                }

                &__content {
                    @extend %grid__col--margin-left-1-lg;
                }
            }
        }
    }
}
