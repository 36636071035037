%border-on-hover {
    @include iterate-breakpoints {
        position: relative;
        outline: 0;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            height: $hr-height;
            transition: background $transition-time;
        }

        &:hover,
        &:focus,
        &.-is-active {
            &::after {
                background: $hr-color;
            }
        }
    }
}
