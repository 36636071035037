.title {
    $root: &;
    display: block;
    font: 200 1em/#{$title-line-height} $title-font-family;
    letter-spacing: -.05em;

    &__prefix {
        display: block;
        font-size: .9em;
        line-height: 1;
    }

    &__label {
        margin: 0;
        padding: .25em;
        font-size: .25em;
        color: $title-label-color;
    }

    &__link {
        outline: none;
        color: inherit;
        text-decoration: none;
        transition: opacity $transition-time;

        &:hover,
        &:focus {
            opacity: .75;
        }
    }

    // stylelint-disable plugin/selector-bem-pattern
    @each $breakpoint, $sizes in $title-sizes {
        @include media($breakpoint) {
            $i: 1;

            @each $size in $sizes {
                @if $size != null {
                    &--h#{$i} {
                        font-size: $size;
                    }
                }
                $i: $i + 1;
            }
        }
    }
    // stylelint-enable plugin/selector-bem-pattern

    &--subtitle {
        margin-top: 2em;
        line-height: $subtitle-line-height;
    }

    &--h5,
    &--h6 {
        letter-spacing: -.025em;
        font-weight: 300;
    }

    // negative vertical margins to level down line-height to start and end title with the baseline
    &--baseline-top {
        &::before {
            content: '';
            display: block;
            margin-top: -(($title-line-height - 1em) / 2) - $title-baseline;
        }
    }

    &--baseline-bottom {
        &::after {
            content: '';
            display: block;
            margin-top: -(($title-line-height - 1em) / 2) - $title-baseline;
        }
    }

    &--baseline {
        @extend #{$root}--baseline-top;
        @extend #{$root}--baseline-bottom;
    }

    &--overflow {
        @extend #{$root}--baseline-top;
        margin-top: -($title-line-height * 1em) + $title-baseline;
        padding-top: .333em;
    }

    &--section {
        color: $color-primary;
    }

    @include media(md, xl) {
        &__prefix {
            display: inline;
            font-size: 1em;
        }

        // Minor titles doesn't change on `md` breakpoint
        &--minor {
            // stylelint-disable plugin/selector-bem-pattern
            $i: 1;
            $sizes: get-map-value($title-sizes, xs);

            @each $size in $sizes {
                @if $size != null {
                    &#{$root}--h#{$i} {
                        font-size: $size;
                    }
                }
                $i: $i + 1;
            }
            // stylelint-enable plugin/selector-bem-pattern
        }
    }

    @include media(xl) {
        &__prefix {
            display: block;
            font-size: .75em;
        }

        &--h1 {
            font-weight: 100;
        }
    }
}
