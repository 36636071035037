.double-section {
    $root: &;
    background: $double-section-color-bg;
    color: $double-section-color-text;
    @include each-breakpoint($section-paddings, padding-top, 'divide', 2);

    &__inner {
        @extend %grid;
    }

    &__section {
        @extend %grid__col;
        position: relative;
        z-index: 1;
        @include each-breakpoint($section-paddings, (padding-top, padding-bottom));

        &:nth-child(odd) {
            z-index: 2;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            z-index: -1;
            right: 100%;
            width: 50vw;
            height: 100%;
        }

        &::after {
            right: 0;
            left: 100%;
        }

        @each $type in ('even', 'odd') {
            &--#{$type} {
                $color-bg: nth(get-map-value($section-colors, $type), 1);
                $color-text: nth(get-map-value($section-colors, $type), 2);
                --color-bg: #{$color-bg}; // stylelint-disable-line
                background: $color-bg;
                color: $color-text;

                &::before,
                &::after {
                    background: var(--color-bg);
                    background: $color-bg; // stylelint-disable-line declaration-block-no-duplicate-properties
                }
            }
        }
    }

    &__footer {
        margin-top: auto;
        padding-top: $page-gutter * 2;
        text-align: right;
        @include each-breakpoint($section-paddings, margin-bottom, 'multiply', -1);
    }

    &__label {
        margin-bottom: $page-gutter * 3;
    }

    @include media(lg) {
        &__section {
            display: flex;
            padding-bottom: 0;

            &:nth-child(odd)::after,
            &:nth-child(even)::before {
                content: none;
            }

            &:nth-child(odd) {
                @extend %grid__col--5-lg;

                @each $bp, $gutter in $grid-gutters {
                    @include media($bp) {
                        box-shadow: #{$gutter / 2} 0 0 var(--color-bg);
                    }
                }
            }

            &:nth-child(even) {
                @extend %grid__col--6-lg;
                @extend %grid__col--margin-left-1-lg;
            }

            &:only-child {
                @extend %grid__col--6-lg;
                @extend %grid__col--padding-left-1-lg;
                margin-left: auto;

                &::before {
                    content: none;
                }

                &::after {
                    content: '';
                }
            }
        }

        &__content {
            position: relative;
        }

        &__footer {
            margin-bottom: 0;
            padding-top: $page-gutter * 3;

            // stylelint-disable-next-line plugin/selector-bem-pattern
            #{$root}__section--even & {
                text-align: left;
            }
        }

        &__label {
            position: relative;
            top: -$page-gutter * 3;
            margin-top: calc(-#{get-bp-value($section-paddings, lg)} - 1em);
            margin-bottom: 0;
            line-height: 1;
        }
    }

    @include media(xl) {
        // stretch vertically
        &__content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            min-width: 1px; // IE11
        }

        &__label {
            margin-top: calc(-#{get-bp-value($section-paddings, xl)} - 1em);
        }
    }
}
