@mixin grid__col--count($suffix: null) {
    // stylelint-disable plugin/selector-bem-pattern
    @for $i from 1 through $grid-columns {
        $percentage: percentage($i / $grid-columns);

        // column width
        &--#{$i}#{$suffix} {
            flex: 0 0 $percentage;
            max-width: $percentage;
        }
    }
    // stylelint-enable plugin/selector-bem-pattern
}

@mixin grid__col--modifiers($suffix: null, $gutter: 0) {
    // stylelint-disable plugin/selector-bem-pattern
    @for $i from 1 through $grid-columns {
        $percentage: percentage($i / $grid-columns);
        // margin - both sides
        &--margin-#{$i}#{$suffix} {
            margin-left: $percentage;
            margin-right: $percentage;
        }

        &--margin-left-#{$i}#{$suffix} {
            margin-left: $percentage;
        }

        &--margin-right-#{$i}#{$suffix} {
            margin-right: $percentage;
        }

        &--margin-0#{$suffix},
        &--margin-left-0#{$suffix},
        &--margin-right-0#{$suffix} {
            margin-left: 0;
            margin-right: 0;
        }

        $padding: calc(#{$percentage} + #{$gutter / 2}); // stylelint-disable-line order/order

        // padding - both sides
        &--padding-#{$i}#{$suffix} {
            padding-left: $padding;
            padding-right: $padding;
        }

        &--padding-left-#{$i}#{$suffix} {
            padding-left: $padding;
        }

        &--padding-right-#{$i}#{$suffix} {
            padding-right: $padding;
        }

        &--padding-0#{$suffix},
        &--padding-left-0#{$suffix},
        &--padding-right-0#{$suffix} {
            padding-left: 0;
            padding-right: 0;
        }
    }
    // stylelint-enable plugin/selector-bem-pattern
}

@mixin grid-spacing($value, $type: margin) {
    #{$type}-left: ($value / 2);
    #{$type}-right: ($value / 2);
}

@mixin grid($gutter) {
    display: flex;
    flex-wrap: wrap;
    @include grid-spacing(-$gutter, margin);
}

@mixin grid__col($gutter) {
    flex: 0 0 100%;
    max-width: 100%;
    min-height: 1px;
    @include grid-spacing($gutter, padding);
}

@mixin grid__gutters($gutter) {
    @include grid-spacing(-$gutter, margin);

    &__col {
        @include grid-spacing($gutter, padding);
    }
}

%grid {
    @each $breakpoint, $size in $breakpoints {
        $suffix: if(media-prefix($breakpoint), -#{media-prefix($breakpoint)}, null);
        $gutter: get-bp-value($grid-gutters, $breakpoint);

        @include media($breakpoint) {

            @include grid__gutters($gutter);

            @if $suffix {
                &-#{$suffix} {
                    @include grid($gutter);
                }
            } @else {
                @include grid($gutter);
            }

            &__col {
                @if $suffix {
                    &-#{$suffix} {
                        @include grid__col($gutter);
                    }
                } @else {
                    @include grid__col($gutter);
                }

                @include grid__col--count($suffix);
            }
        }
    }

    // Modifiers have to go last to be not overwritten by default columns count
    @each $breakpoint, $size in $breakpoints {
        $suffix: if(media-prefix($breakpoint), -#{media-prefix($breakpoint)}, null);
        $gutter: get-bp-value($grid-gutters, $breakpoint);

        @include media($breakpoint) {
            &__col {
                @include grid__col--modifiers($suffix, $gutter);
            }
        }
    }
}
