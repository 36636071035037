@mixin grid--overflow($breakpoint, $cols, $gutter) {
    $value: get-map-value($page-side-offsets, $breakpoint);
    $width: calc(#{percentage($cols / $grid-columns)} + #{$value});
    flex: 0 0 $width;
    max-width: $width;
    margin-left: -$value;
    margin-right: -$value;
}

.timeline-item {
    $root: &;
    @extend %grid;
    @extend %animation;
    position: relative;

    &__col {
        @extend %grid__col;
        @extend %animation__item;
        @extend %animation__item--loggy;
        z-index: 2;
    }

    &__media {
        display: block;
        width: 100%;
    }

    &__description {
        position: relative;
        padding: $page-gutter * 3;
        background: $timeline-desc-color-bg;
        color: $timeline-desc-color-text;
    }

    &__title {
        &::before,
        &::after {
            @extend %animation__item;
            animation-delay: $transition-time * 3 !important; // stylelint-disable-line declaration-no-important
        }
    }

    &__link {
        margin-top: 2em;
    }

    &--odd &__col:nth-child(even),
    &--even &__col:nth-child(odd) {
        @extend %animation__item--from-left;
    }

    &--odd &__col:nth-child(odd),
    &--even &__col:nth-child(even) {
        @extend %animation__item--from-right;
    }

    @include media(xs, md) {
        &::before {
            content: '';
            position: absolute;
            left: -50%;
            bottom: $timeline-bullet-size / 2;
            width: 200%;
            border-top: $timeline-line-width dotted $timeline-line-color;
        }

        &::after {
            content: $timeline-bullet-svg;
            z-index: 2;
            display: block;
            margin: ($page-gutter * 2) auto 0;
            width: $timeline-bullet-size;
            height: $timeline-bullet-size;
            background: var(--color-bg);
            box-shadow: 0 0  0 .5em var(--color-bg);
            border-radius: 50%;
        }

        &:last-child {
            &::before,
            &::after {
                content: none;
            }
        }
    }

    @include media(xs, lg) {
        &__text {
            line-height: 1.5;
        }
    }

    @include media(md) {
        display: flex;
        align-items: flex-end;

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            @include each-breakpoint($section-paddings, (top, bottom), 'multiply', -.5);
            margin: 0 ($timeline-line-width / -2);
            border-left: $timeline-line-width dotted $timeline-line-color;
        }

        &__col {
            $cols: 5;
            @extend %grid__col--#{$cols}-md;

            &--wide {
                @each $bp, $gutter in $grid-gutters {
                    @include media($bp) {
                        @include grid--overflow($bp, $cols, $gutter);
                    }
                }
            }
        }

        &__link {
            margin-top: 3em;
        }

        // Timeline line fade-out on the ends
        &:first-child,
        &:last-child {
            &::after {
                content: '';
                position: absolute;
                left: 50%;
                height: 25%;
                width: $timeline-line-width;
                margin: 0 ($timeline-line-width / -2);
            }
        }

        &:first-child {
            &::before,
            &::after {
                top: 0;
            }

            &::after {
                background: linear-gradient(to top, transparent 0, var(--color-bg) 100%);
            }
        }

        &:last-child {
            &::before,
            &::after {
                bottom: 25%;
            }

            &::after {
                background: linear-gradient(to bottom, transparent 0, var(--color-bg) 100%);
            }
        }

        &__title {
            align-self: stretch;

            &::before,
            &::after {
                content: '';
                position: absolute;
                z-index: 3;
                left: 0;
            }

            // Bullet
            &::before {
                content: $timeline-bullet-svg;
                display: block;
                width: 1em;
                height: 1em;
                background: var(--color-bg);
                box-shadow: 0 0  0 .25em var(--color-bg);
                border-radius: 50%;
                transform: scale(.4) translateX(-50%);
                transform-origin: left center;
            }

            // Hyphen
            &::after {
                width: 1em;
                margin: .5em -.5em;
                border-top: 2px solid $timeline-color-hyphen;
            }
        }

        &--even {
            #{$root}__col {
                &:nth-child(odd) {
                    margin-right: auto;
                }

                &:nth-child(even) {
                    margin-left: auto;
                }
            }
        }

        &--odd {
            flex-direction: row-reverse;

            #{$root}__col {
                &:nth-child(odd) {
                    margin-left: auto;
                }

                &:nth-child(even) {
                    margin-right: auto;
                }
            }


            #{$root}__title {
                &::before,
                &::after {
                    left: auto;
                    right: 0;
                }

                &::before {
                    transform: scale(.4) translateX(50%);
                    transform-origin: right center;
                }
            }
        }

        @each $bp, $value in $breakpoints {
            @include media($bp) {
                $padding: grid-column-width($bp);

                &__title {
                    &::before {
                        $offset: $padding + (get-bp-value($grid-gutters, $bp) / 2) + $timeline-line-width;
                        margin-left: -$offset;
                        margin-right: -$offset;
                    }
                }

                @include media-matching($bp, lg) {
                    &__description {
                        padding: ($padding * 2 / 3) $padding;
                    }
                }
            }
        }
    }

    @include media(lg) {
        &__description {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            min-height: $timeline-desc-min-height-lg;
            min-width: 1px; // IE11
        }

        &__title,
        &__text {
            max-width: 100%;
        }

        &__title {
            margin-bottom: auto;
        }

        &__link {
            margin-bottom: -2em;
        }
    }


    @include media(xl) {
        &:first-child {
            &::before,
            &::after {
                top: 25%;
            }
        }
    }
}
