.player-audio {
    display: block;
    margin-bottom: $page-gutter * 2;
    background: $player-audio-color-bg;
    color: $player-audio-color-text;

    &__header {
        display: flex;
        align-items: center;
        width: 100%;
        padding: $page-gutter;
        border-bottom: 1px solid $player-audio-color-border;
    }

    &__title {
        flex-grow: 1;
        line-height: 1.25;
    }

    &__button {
        flex-shrink: 0;
        margin-left: $page-gutter;
    }

    @include media(md) {
        margin-bottom: $page-gutter * 3;

        &__header {
            padding: ($page-gutter * 1.5) ($page-gutter * 2);
        }
    }
}
