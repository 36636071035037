$sticky-nav-color-bg: rgba($grey-dark, .7);
$sticky-nav-color-text: $white;
$sticky-scroll-hide-offset: 20px;

.sticky-nav {
    $root: &;
    position: relative;
    z-index: z-index('subheader');
    display: block;
    width: 100%;

    &__inner {
        max-width: $page-max-width;
        margin: 0 auto;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        background: $sticky-nav-color-bg;
        color: $sticky-nav-color-text;
    }

    @media (hover: none) {
        &__inner {
            overflow-x: auto;
        }
    }

    &__list {
        display: inline-flex;
        align-items: stretch;
        min-width: 100%;
        @include each-breakpoint($page-side-paddings, (padding-left, padding-right));
    }

    &__item {
        display: flex;
        padding: 0 ($page-gutter * 2);
        font: normal 1.5rem $font-headings-family;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        padding: $page-gutter 0;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        outline: none;
        color: inherit;
        text-decoration: none;
        text-align: center;
        transition: border $transition-time;

        &:hover {
            border-bottom-color: currentColor;
        }

        &.-is-active {
            border-bottom-color: $color-primary;
            opacity: 1;
        }
    }

    &__logo {
        display: block;
        min-width: 4em;
        max-width: 8em;

        img {
            display: block;
            max-height: 2em;
            margin: auto;
            backface-visibility: hidden;
        }
    }

    &--full {
        #{$root} {
            &__list {
                justify-content: space-between;
            }
        }
    }

    &.-is-sticky {
        position: fixed;
    }

    @include media(md) {
        &__item {
            font-size: 1.333rem;
        }
    }

    @include media(xxl) {
        &__item {
            padding: 0 ($page-gutter * 3);
            font-size: 1.667rem;
        }

        &__link {
            padding: ($page-gutter * 2) 0;
        }
    }
}
