.select {
    position: relative;
    border: 1px solid $select-border-color;

    &__form-element {
        height: $page-gutter * 3.5;
        width: 100%;
        padding-left: $page-gutter;
        padding-right: 2.5em;
        border: none;
        background: none;
        font-weight: 300;
        font-family: $font-base-family;
        @include each-breakpoint($select-font-sizes, font-size);
        text-overflow: ellipsis;
        cursor: pointer;
        appearance: none;
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: $page-gutter;
        height: $select-icon-size;
        width: $select-icon-size;
        margin-top: -($select-icon-size / 2);
        color: $select-icon-color;
        pointer-events: none;

        svg {
            @extend %svg;
        }
    }
}
