.tools {
    $root: &;
    display: inline-flex;
    font: 300 13px/1 $font-base-family;

    &__item {
        padding: 0 1.333em;
        border-left: 1px solid $tools-color-border;
        white-space: nowrap;

        &:first-child {
            border: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &__link {
        display: block;
        margin: 0;
        padding: 0;
        outline: 0;
        border: 0;
        background: none;
        font: inherit;
        color: inherit;
        text-decoration: none;
        transition: opacity $transition-time;
        cursor: pointer;

        &:hover,
        &:focus {
            opacity: .75;
        }

        &.-is-active {
            #{$root}__icon {
                transform: rotate3d(1, 0, 0, 180deg);
            }
        }
    }

    &__icon {
        height: 1.333em;
        width: 1.333em;
        color: $tools-color-icon;
        transition: transform $transition-time;
    }
}
