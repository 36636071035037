.share {
    display: block;

    &__list {
        display: inline-flex;
        vertical-align: middle;
        margin: 0 $page-gutter;
    }

    &__link {
        display: block;
        width: 3.5em;
        height: 3.5em;
        padding: 1em;
        border: 1px solid $share-color-border;
        outline: 0;
        background: none;
        color: $share-color-icon;
        text-decoration: none;
        transition: color $transition-time;

        &:hover,
        &:focus {
            color: inherit;
        }
    }

    svg {
        @extend %svg;
    }

    @include media(xl) {
        $size: grid-column-width(xl) - get-bp-value($grid-gutters, xl);

        &__title {
            display: block;
            width: $size;
            margin-bottom: .5em;
        }

        &__list {
            flex-direction: column;
            margin: 0;
        }

        &__link {
            width: $size;
            height: $size;
            padding: 0;

            svg {
                width: 30%;
                height: 30%;
                margin: 35%;
            }
        }
    }
}
