@keyframes animation__item--show {
    to {
        opacity: 1;
    }
}

@keyframes animation__item--slide {
    to {
        opacity: 1;
        transform: translate(0, 0);
    }
}

%animation {
    $root: &;

    &__item {
        opacity: 0;
        animation: animation__item--show ($transition-time * 2) forwards;

        @for $i from 1 through 19 {
            &:nth-child(#{$i}) {
                animation-delay: ($i - 1) * ($transition-time * .5);
            }
        }

        &:nth-child(n+20) {
            animation-delay: (20 - 1) * ($transition-time * .5);
        }

        &--from-bottom {
            transform: translate(0, $page-gutter * 3);
            animation-name: animation__item--slide;
        }

        &--from-left {
            transform: translate(-$page-gutter * 3, 0);
            animation-name: animation__item--slide;
        }

        &--from-right {
            transform: translate($page-gutter * 3, 0);
            animation-name: animation__item--slide;
        }

        &--loggy {
            animation-duration: $transition-time * 3;

            @for $i from 1 through 19 {
                &:nth-child(#{$i}) {
                    animation-delay: $i * ($transition-time * .75);
                }
            }

            &:nth-child(n+20) {
                animation-delay: 20 * ($transition-time * .75);
            }
        }
    }

    &.-is-animation-inactive:not(.-has-animation-init) {
        #{$root}__item {
            animation: none;
        }
    }
}
