.gallery-slider {
    position: relative;

    &__item {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity $transition-time $transition-time;

        &.-is-active {
            position: relative;
            z-index: 1;
            opacity: 1;
            transition-delay: 0s;
        }
    }
}
