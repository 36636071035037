.load-more {
    $root: &;
    position: relative;

    &__loader,
    &__footer {
        text-align: center;
    }

    &__loader {
        display: none;
    }

    &__icon,
    &__button {
        @include each-breakpoint($section-paddings, margin-top, 'multiply', 1.5);
    }

    &__button {
        &.-is-hidden {
            display: none;
        }
    }

    &__icon {
        width: 3em;
        height: 3em;
    }

    &.-is-loading {
        #{$root}__loader {
            display: block;
        }
    }
}
