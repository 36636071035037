.carousel-dots {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    margin-top: $page-gutter * 3;

    &__link {
        display: block;
        margin-right: $carousel-dot-size / 2;
        padding: 0;
        height: $carousel-dot-size;
        width: $carousel-dot-size;
        overflow: hidden;
        border: 0;
        outline: none;
        background: $carousel-dot-color;
        color: inherit;
        text-indent: 100%; // hide text label
        opacity: .1;
        transition: all $transition-time;
        cursor: pointer;

        &:hover,
        &:focus {
            opacity: .2;
        }

        &.-is-active {
            background: $carousel-dot-color--active;
            opacity: 1;
        }
    }
}
