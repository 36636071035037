$no-results-color-border: $border-color;
$no-results-color-text: $color-primary;
$no-results-color-icon-sizes: (
    xs: 110px,
    md: 165px,
    xl: 220px,
);
$no-results-color-icon-offset-desktop: $page-gutter * 4;
$no-results-color-icon: $color-primary-light;
$no-results-text-sizes: (
    xs: 1rem,
    md: 1.5rem,
    xl: 2rem,
);

.no-results {
    $root: &;

    &__content {
        border: 1px solid $no-results-color-border;
    }

    &__bottom {
        margin-top: $page-gutter;
    }

    &__text {
        margin: 1.25em 0;
        @include each-breakpoint($no-results-text-sizes, font-size);
        line-height: 1.333;
        color: $no-results-color-text;
    }

    &__footer {
        margin-top: $page-gutter * 2;
    }

    &__icon {
        display: block;
        margin: 0 auto;
        @include each-breakpoint($no-results-color-icon-sizes, (width, height));
        color: $no-results-color-icon;

        svg {
            @extend %svg;
        }
    }

    @include media(xs, md) {
        padding-top: get-map-value($no-results-color-icon-sizes, 'xs');

        &__content {
            padding: $page-gutter $page-gutter 0;
        }

        &__icon {
            margin-top: -#{get-map-value($no-results-color-icon-sizes, 'xs')};
        }

        &__button {
            display: block;
        }
    }

    @include media(md) {
        &__content {
            @include each-breakpoint($page-side-paddings, (padding-left, padding-right));
            @include each-breakpoint($page-side-paddings, (margin-left, margin-right), 'multiply', -1);
        }

        &__bottom {
            margin-top: $page-gutter * 2;
        }

        &__footer {
            margin-top: $page-gutter * 3;
        }

        &__icon {
            float: right;
            margin: (-$no-results-color-icon-offset-desktop) 0 0 $no-results-color-icon-offset-desktop;
        }
    }

    @include media(xl) {
        &__footer {
            margin-top: $page-gutter * 4;
        }
    }
}
