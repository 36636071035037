.cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: z-index('cookies');
    display: none;
    width: 100%;
    // padding: $page-gutter 0;
    padding: 0;
    background: $cookies-color-bg;
    color: $cookies-color-text;
    font-size: $cookies-font-size;

    &__container {
        position: relative;
        display: block;
    }

    &__text {
        padding: $page-gutter;
    }

    &__link {
        color: $cookies-color-icon;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        background: none;
        font-size: .75em;
        transition: transform $transition-time;
        cursor: pointer;

        &:hover {
            transform: rotate(90deg);
        }
    }

    &__icon {
        width: 2.5em;
        height: 2.5em;
        color: $cookies-color-icon;
    }

    &__more {
        @extend %hyphen;
        display: inline-block;
        margin-left: $page-gutter;
    }


    &.-is-not-accepted {
        display: block;
    }

    @include media(md) {
        &__container {
            display: flex;
            align-items: flex-start;
            // padding-top: $page-gutter * 3;
            padding-top: 0;
        }

        &__text {
            width: 75%;
            padding: 0 $page-gutter;
        }

        &__icon,
        &__button {
            flex-shrink: 0;
            margin-left: auto;
        }

        &__accept {
            text-align: right;
        }
    }
}

