.simple-teaser {
    $root: &;
    @extend %grid;

    &__title {
        line-height: .925;
        color: $simple-teaser-title-color;
    }

    &__header,
    &__content {
        @extend %grid__col;
        position: relative;
    }

    &__content {
        @extend %grid__col--padding-1;
        z-index: 2;
        margin-top: -$page-gutter;
        padding-top: $page-gutter * 3;
        padding-bottom: $page-gutter * 2;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            @include each-breakpoint($grid-gutters, (left, right), 'divide', 2);
            display: block;
            border: 1px solid $simple-teaser-border-color;
            pointer-events: none;
        }
    }

    @include media(xs, sm) {
        &__title {
            font-size: 3.333rem;
        }
    }

    @include media(md) {
        align-items: center;

        &__title {
            margin-bottom: $page-gutter;
            font-weight: 100;
        }

        &__header {
            @extend %grid__col--5-md;
        }

        &__content {
            @extend %grid__col--7-md;
            @extend %grid__col--padding-left-2-md;
            margin-top: 0;
            padding-top: $page-gutter * 4;
            padding-bottom: $page-gutter * 4;
        }
    }

    @include media(lg) {
        &__header {
            @extend %grid__col--4-lg;
        }

        &__content {
            @extend %grid__col--8-lg;
            padding-top: $page-gutter * 6;
            padding-bottom: $page-gutter * 6;
        }
    }

    @include media(xl) {
        &__header {
            @extend %grid__col--margin-left-1-xl;
        }

        &__content {
            @extend %grid__col--7-xl;
            @extend %grid__col--padding-left-2-xl;
            @extend %grid__col--padding-right-1-xl;
        }
    }
}
