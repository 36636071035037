.list {
    $root: &;
    @extend .text;
    display: block;

    &__item {
        margin-bottom: $page-gutter;
        margin-left: 1em;
        list-style: disc outside;
    }

    &--ordered {
        #{$root}__item {
            list-style: decimal;
        }
    }
}
