$modal-newsletter-color-title: $color-primary;

.modal-newsletter {
    &__title {
        display: none;
    }

    &__image {
        display: block;

        img {
            display: block;
            width: 100%;
        }
    }

    &__text,
    &__label {
        margin: 0 0 1em;
        font-size: 11px;
    }

    &__text {
        line-height: 1.667;
    }

    &__label {
        font-weight: 600;
        letter-spacing: .04em;
    }

    @include media(xs, md) {
        &__content {
            margin-bottom: $page-gutter * 2;
        }

        &__image {
            $offset: ($page-gutter * 2.5) - 1px; // 1px due to border
            margin-left: -$offset;
            margin-right: -$offset;
        }

        &__image-inner {
            &--desktop {
                display: none;
            }
        }
    }

    @include media(md) {
        display: flex;
        align-items: center;

        &__content,
        &__image {
            flex: 0 0 50%;
            flex-grow: 1;
        }

        &__content {
            padding-right: $page-gutter;
        }

        &__image-inner {
            position: relative;
            right: -($page-gutter * 3);
            display: block;

            &--mobile {
                display: none;
            }
        }

        &__title {
            display: block;
            margin-bottom: $page-gutter;
            color: $modal-newsletter-color-title;
        }
    }

    @include media(lg) {
        &__title {
            margin-bottom: $page-gutter * 2;
        }

        &__text,
        &__label {
            margin: 0 0 1.5em;
            font-size: 14px;
        }

        &__label {
            letter-spacing: .12em;
        }
    }

    @include media(xl) {
        &__image-inner {
            width: calc(100% + #{$page-gutter * 8});
        }
    }
}
