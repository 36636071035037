.heading {
    $root: &;
    @extend %animation;
    position: relative;
    z-index: z-index('heading');

    &__inner {
        @extend %grid;
    }

    &__content,
    &__media {
        @extend %grid__col;
    }

    &__media {
        @extend %animation__item;
        @extend %animation__item--from-bottom;
    }

    &__media-logo {
        margin-bottom: $page-gutter;

        img {
            display: block;
            max-width: percentage(3 / 5);
            margin-left: auto;
            backface-visibility: hidden;
        }
    }

    &__lead {
        @extend %hyphen;
        @extend %hyphen--wide;
        margin: ($page-gutter * 3) 0 ($page-gutter * 6);
    }

    &__bottom {
        @include each-breakpoint($section-paddings, margin-top);
    }

    &--on-jumbotron {
        padding-top: $page-gutter;

        #{$root}__title {
            margin-top: -(1em - $font-headings-baseline);
        }
    }

    @include media(md) {
        &__inner {
            flex-wrap: nowrap;
        }

        &__content {
            @extend %grid__col--7-md;

            &--full,
            &--narrow {
                @extend %grid__col--12-md;
            }
        }

        &__media {
            @extend %grid__col--4-md;
            @include each-breakpoint($section-paddings, (margin-top, margin-bottom), 'multiply', -1);

            &:not(&--narrow) {
                @extend %grid__col--5-md;
            }
        }

        &__media-inner {
            position: relative;
        }

        &__media-logo {
            position: absolute;
            bottom: 100%;
            left: 0;
            width: 100%;
            margin-bottom: $page-gutter * 2;
        }

        &__lead {
            padding-right: grid-column-width(md);
        }
    }

    @include media(lg) {
        &__content {
            &--narrow {
                @extend %grid__col--7-lg;
            }
        }
    }
}
