.link {
    $root: &;
    @extend %hyphen;
    display: flex;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    background: transparent;
    color: inherit;
    font: 300 1em/#{$link-line-height} $font-headings-family;
    @include each-breakpoint($link-font-sizes, font-size);
    text-align: left;
    text-decoration: none;

    &::after {
        content: '';
        transition: transform $transition-time;
    }

    &:hover,
    &:focus {
        &::after {
            transform: translateX($page-gutter / 2);
        }
    }
}
