$embed-ratio-height: get-map-value($embed-ratio, height);
$embed-ratio-width: get-map-value($embed-ratio, width);

.embed {
    position: relative;
    height: 0;
    padding-bottom: percentage($embed-ratio-height / $embed-ratio-width);

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: 0;
        background: none;
    }
}
