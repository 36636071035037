.footer-nav {
    display: flex;

    &__section {
        flex-grow: 1;
        margin-right: $page-gutter * 2;

        &:last-child {
            margin-right: 0;
        }
    }

    &__title {
        margin-bottom: $page-gutter * 2;
    }

    @include media(md) {
        &__section {
            margin-right: $page-gutter * 6;
        }
    }

    @include media(xl) {
        &__section {
            margin-right: $page-gutter * 2;
        }
    }
}
