.listing {
    $root: &;
    display: block;

    &__item {
        margin-bottom: $page-gutter;
        break-inside: avoid;

        &--spaced {
            margin-bottom: $page-gutter * 2;
        }
    }

    &__link {
        @extend %link-simple;
    }

    &--columns {
        column-count: 2;
        column-gap: $page-gutter * 2;
    }

    &--tight {
        #{$root}__item {
            margin-bottom: $page-gutter / 2;
        }
    }

    &--loose {
        #{$root}__item {
            margin-bottom: $page-gutter * 2;
        }
    }

    @include media(md, xl) {
        &--columns {
            column-gap: $page-gutter * 6;
        }
    }
}
