@keyframes tooltip--show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes tooltip--hide {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.tooltip {
    $root : &;
    position: relative;
    z-index: z-index('tooltip');
    display: inline-block;
    width: $tooltip-width;
    transition: visibility 0s $transition-time;
    animation: tooltip--hide $transition-time;

    // stylelint-disable-next-line plugin/selector-bem-pattern
    &[aria-hidden="false"] {
        transition: none;
        animation: tooltip--show $transition-time;
    }

    &__content {
        display: block;
        border: $tooltip-border-width solid $tooltip-color-border;
        background: $tooltip-color-bg;
        color: $tooltip-color-text;
        text-decoration: none;

        &:hover,
        &:focus {
            #{$root}__image img {
                transform: scale($image-hover-scale);
            }
        }
    }

    &__arrow {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border: $tooltip-arrow-size solid transparent;
    }

    &__header {
        padding: $page-gutter ($page-gutter * 2);
    }

    &__image {
        display: block;
        overflow: hidden;

        img {
            display: block;
            width: $tooltip-image-width;
            height: $tooltip-image-height;
            background: $tooltip-image-color-bg;
            transition: transform $transition-time;
        }
    }

    &__title {
        margin-bottom: $page-gutter;
        font-weight: 500;
    }

    // We need to style it by attr as it's enforced by external library
    /* stylelint-disable plugin/selector-bem-pattern */
    &[x-placement^="top"] {
        padding-bottom: $tooltip-arrow-size * 2;

        #{$root}__arrow {
            bottom: $tooltip-arrow-size;
            left: 50%;
            border-bottom-width: 0;
            border-top-color: $tooltip-color-bg;
        }
    }

    &[x-placement^="bottom"] {
        padding-top: $tooltip-arrow-size * 2;

        #{$root}__arrow {
            top: $tooltip-arrow-size;
            left: 50%;
            border-top-width: 0;
            border-bottom-color: $tooltip-color-bg;
        }
    }
    /* stylelint-enable plugin/selector-bem-pattern */
}
