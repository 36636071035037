.media {
    $root: &;
    position: relative;
    overflow: hidden;

    &__object {
        display: block;
        width: 100%;
    }

    &__badge {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;
    }

    &__play {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        display: block;
        width: 5.5em;
        height: 5.5em;
        padding: 1.75em;
        border-radius: 50%;
        box-shadow: 0 0 0 .825em $play-color-outline;
        background: $play-color-bg;
        color: $play-color-text;
        transform: translate(-50%, -50%);

        svg {
            @extend %svg;
            max-height: 2em;
        }
    }

    &__marker {
        position: absolute;
        z-index: 8;
    }

    &--playable {
        outline: 0;
        cursor: pointer;

        #{$root} {
            &__object {
                transform: scale(1);
                transition: transform $transition-time;
            }
        }

        &:hover,
        &:focus {
            #{$root} {
                &__object {
                    transform: scale(1.1);
                }
            }
        }
    }
}
