.two-media {
    $root: &;
    @extend %grid;

    &__header {
        @extend %grid__col;
    }

    &__col {
        @extend %grid__col;
        margin-top: $page-gutter * 2;

        &:first-child {
            margin-top: 0;
        }
    }

    &__title {
        color: $two-media-title-color;
    }

    &__label {
        margin: 0;
    }

    &__image {
        margin: ($page-gutter * 2) 0 $page-gutter;
    }

    &__footer {
        padding-top: $page-gutter;
    }

    @include media(md) {
        &__header {
            @extend %grid__col--10-md;
            @extend %grid__col--margin-1-md;
            order: -2;
        }

        &__footer {
            margin-top: auto;
            padding-top: $page-gutter * 2;
        }

        &__col {
            @extend %grid__col--5-md;
            display: flex;
            flex-direction: column;
            margin-top: $page-gutter * 2;

            &--heading {
                justify-content: flex-end;
                order: -1;
            }

            &--odd {
                @extend %grid__col--7-md;
                @extend %grid__col--padding-1-md;

                #{$root}__image {
                    max-width: none;

                    @each $bp, $val in $breakpoints {
                        @include media-matching(md, $bp) {
                            @include media($bp) {
                                $offset: get-bp-value($page-side-paddings, $bp);
                                $columnWidth: grid-column-width($bp);
                                $total: $offset + $columnWidth;
                                width: calc(100% + #{$total});
                                margin-left: -$total;
                            }
                        }
                    }
                }
            }

            &--even {
                #{$root}__image {
                    margin-top: 0;
                }
            }
        }

        &__lead {
            margin-bottom: auto;
        }

        &__image {
            margin: ($page-gutter * 2) 0;
        }
    }

    @include media(xl) {
        &__col {
            &--odd {
                @extend %grid__col--padding-1-xl; // because of different gutter
            }
        }
    }
}
