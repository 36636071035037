.contacts {
    @extend %grid;

    &__inner {
        @extend %grid__col;
        margin-bottom: $page-gutter * 2;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include media(md) {
        &__inner {
            @extend %grid__col--11-md;
            @extend %grid__col--margin-left-1-md;
        }
    }

    @include media(lg) {
        &__inner {
            @extend %grid__col--3-lg;
            @extend %grid__col--margin-left-1-lg;
            margin-bottom: 0;

            &--double {
                @extend %grid__col--7-lg;
            }
        }
    }
}
