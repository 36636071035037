.footer {
    $footer-border: 1px solid $footer-color-border;
    display: block;
    padding-bottom: get-map-value($footer-bottom-margin, xs);
    background: $footer-color-bg;
    color: $footer-color-text;
    font-size: get-map-value($footer-item-font-size, xs);

    &__wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 -#{map-get($page-side-paddings, xs)};
        border-top: $footer-border;
        border-bottom: $footer-border;
    }

    &__newsletter,
    &__navigation {
        padding: $footer-navigation-padding-xs map-get($page-side-paddings, xs);
    }

    &__newsletter,
    &__navigation,
    &__socials {
        border-bottom: $footer-border;
    }

    &__socials,
    &__info {
        padding: $footer-container-padding map-get($page-side-paddings, xs);
    }

    @include media(md) {
        padding-bottom: get-map-value($footer-bottom-margin, md);
        font-size: get-map-value($footer-item-font-size, md);

        &__wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            padding-right: get-map-value($footer-social-width, md);
            margin: 0;
            border: $footer-border;
        }

        &__newsletter,
        &__navigation {
            padding: $footer-navigation-padding-horizontal $footer-navigation-padding-vertical;
        }

        &__navigation {
            margin-bottom: auto;
            border-bottom: 0;
        }

        &__socials {
            position: absolute;
            top: 0;
            right: 0;
            width: get-map-value($footer-social-width, md);
            height: 100%;
            padding: 0;
            border-left: $footer-border;
            border-bottom: 0;
        }

        &__info {
            padding-left: $footer-navigation-padding-vertical;
            padding-right: $footer-navigation-padding-vertical;
            border-top: $footer-border;
        }
    }

    @include media(xs, lg) {
        &__newsletter {
            order: -1;
        }
    }

    @include media(lg) {
        &__wrapper {
            padding-right: get-map-value($footer-social-width, lg);
            flex-direction: row;
            flex-wrap: wrap;
        }

        &__navigation,
        &__newsletter {
            width: 50%;
        }

        &__newsletter {
            display: block;
            border: 0;
            border-left: $footer-border;
        }

        &__navigation,
        &__newsletter,
        &__info {
            padding: ($page-gutter * 2) $footer-navigation-padding-horizontal;
        }

        &__socials {
            width: get-map-value($footer-social-width, lg);
        }
    }

    @include media(xl) {
        padding-bottom: get-map-value($footer-bottom-margin, xl);
        font-size: get-map-value($footer-item-font-size, xl);

        &__wrapper {
            padding-right: get-map-value($footer-social-width, xl);
        }

        &__navigation,
        &__newsletter {
            padding: $footer-navigation-padding-horizontal;
        }

        &__socials {
            width: get-map-value($footer-social-width, xl);
        }
    }
}
