@keyframes modal__overlay--fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes modal__inner--slide-in {
    from {
        transform: translateY($page-gutter * 2);
    }

    to {
        transform: translateY(0);
    }
}

.modal {
    $root: &;
    display: none;

    &__overlay {
        position: fixed;
        z-index: z-index('modal');
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        background: $modal-color-overlay;
        animation: modal__overlay--fade-in $transition-time * 2;
    }

    &__inner {
        @extend %page-max-width;
        display: block;
        width: 100%;
        margin: auto;
        background: $modal-color-bg;
        color: $modal-color-text;
        animation: modal__inner--slide-in $transition-time * 2;
    }

    &__header {
        position: relative;
        overflow: hidden;
        background: $modal-header-color-bg;
        color: $modal-header-color-text;

        // Make sure it will always be at least as high as page header
        @each $bp, $height in $header-heights {
            @include media($bp) {
                $nav-height: get-bp-value($header-nav-heights, $bp);
                min-height: $height - $nav-height;
            }
        }
    }

    &__description {
        margin-bottom: $page-gutter * 2;
    }

    &__footer {
        position: relative;
        overflow: hidden;
        margin-top: -($page-gutter * 2 + 1px); // 1px is for border
        background: $modal-footer-color-bg;
        color: $modal-footer-color-text;
    }

    &__container {
        @extend %container-padding-set;
        position: relative;
        padding-top: $page-gutter;
        padding-bottom: $page-gutter;

        &--in-content {
            padding-top: $page-gutter * 2;
            padding-bottom: $page-gutter * 2;
        }

        &--in-header {
            padding-right: 4em; // space for close button
        }

        &--in-footer {
            padding-bottom: $page-gutter * 2;
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        margin: ($page-gutter / 2);
        margin-left: auto;
    }

    &--empty {
        #{$root} {
            &__close {
                margin: 0;
            }

            &__container {
                padding: 0;
            }
        }
    }

    &--embed {
        $ratio-height: get-map-value($embed-ratio, height);
        $ratio-width: get-map-value($embed-ratio, width);

        #{$root}__inner {
            width: ($ratio-width / $ratio-height) * 100vh;
        }
    }

    &.-is-open {
        display: block;
    }

    @include media(xs, md) {
        &--newsletter {
            #{$root} {
                &__container {
                    margin-left: $page-gutter;
                    margin-right: $page-gutter;
                    border: 1px solid $modal-color-border;

                    &--in-header {
                        margin-top: $page-gutter;
                        border-bottom: 0;
                    }

                    &--in-footer {
                        margin-bottom: $page-gutter;
                        border-top: 0;
                    }
                }
            }
        }
    }

    @include media(md) {
        &__container {
            $padding: grid-column-width(md) + get-bp-value($page-side-paddings, md);
            margin: ($page-gutter * 3) $padding;
            border: 1px solid $modal-color-border;

            &--in-header {
                padding-right: 0;
            }
        }

        &__close {
            position: absolute;
            margin: $page-gutter;
        }

        &--empty {
            #{$root}__container {
                margin: 0;
                padding: 0;
                border: 0;
            }
        }

        &--newsletter {
            #{$root} {
                &__inner {
                    position: relative;
                }

                &__header {
                    position: static;
                    height: 0;
                    min-height: 0;
                    overflow: hidden;
                }

                &__container {
                    padding: ($page-gutter * 2) $page-gutter * 3;
                    margin: 0;
                    border: 0;

                    &--in-content {
                        padding: ($page-gutter * 4) ($page-gutter * 3);
                    }

                    &--in-header {
                        position: static;
                    }
                }

                &__footer {
                    margin: 0;
                }

                &__close {
                    margin: $page-gutter / 4;
                    padding-top: 0;
                    padding-bottom: 0;
                    background: none;
                    transform: scale(.75);
                }
            }
        }
    }

    @include media(lg) {
        &__container {
            $padding: grid-column-width(lg);
            margin: ($page-gutter * 3) $padding;
            padding: $page-gutter * 3;
        }
    }

    @include media(xl) {
        &__inner {
            max-width: $modal-max-width;

            &--full {
                max-width: grid-width(xl);
            }
        }

        &__container {
            $padding: grid-column-width(xl);
            margin: ($page-gutter * 5) $padding;
        }

        &--empty {
            #{$root} {
                &__inner {
                    max-width: grid-width(xl);
                }
            }
        }

        &--newsletter {
            #{$root} {
                &__inner {
                    $grid-gutter: get-bp-value($grid-gutters, xl);
                    max-width: ((grid-width(xl) + $grid-gutter) * (($grid-columns - 2) / $grid-columns)) + $grid-gutter;
                }

                &__container {
                    padding: ($page-gutter * 4) ($page-gutter * 6);
                }
            }
        }
    }

    @include media(md) {
        &__container {
            &--in-header {
                margin-bottom: 0;
                border-bottom: 0;
            }

            &--in-content {
                margin-top: 0;
                border-top: 0;
            }
        }
    }
}
