.slider-nav {
    $root: &;
    $width: 2em;
    display: block;
    width: 3.333em;
    margin: -($width / 2) 0;
    padding: 1em 0;
    border: 0;
    outline: 0;
    background: rgba($slider-button-color-bg, .7);
    color: $slider-button-color-text;
    font: 500 12px $font-headings-family;
    text-align: center;
    text-transform: uppercase;
    transition: background $transition-time;
    cursor: pointer;

    &__text {
        display: none;
    }

    &__icon {
        color: $color-primary-light;
    }

    &:hover,
    &:focus {
        background: $slider-button-color-bg;
    }

    @include media(xl) {
        $width: $page-gutter * 17;
        @extend %hyphen-xl;
        width: $width;
        margin: ($width / 2) 0;
        padding: 1.5em;

        &__icon {
            display: none;
        }

        &__text {
            display: inline;
        }

        // hyphen
        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            width: 1.25em;
            margin: 0 -.625em;
            transform: rotate(90deg);
        }
    }
}
