@import "../../config/settings.json";
$breakpoints: () !default;
$promotion: () !default;
$boxes: () !default;

@import "mixins";

// *** COLORS *** //
$black: #000;
$grey-dark: #1c1a19;
$grey-light: #f4f2ed;
$white: #fff;
$color-primary: #c7934d;
$color-primary-light: #d8ad68;

// *** PAGE (GLOBAL) SETTINGS *** //
$page-gutter: 12px;

$grid-columns: 12;
$grid-gutters: (
    xs: $page-gutter,
    xl: $page-gutter * 2,
);

$page-min-width: 320px;
$page-max-width: get-bp-value($breakpoints, 'xxl') * 1px;

$page-side-paddings: (
    xs: 16px,
    md: 30px,
    xl: 24px,
    xxl: 116px,
);

$narrow-max-columns: ($grid-columns - 2) / $grid-columns;
$narrow-max-width: (grid-width(xl) * $narrow-max-columns) - get-bp-value($page-side-paddings, xl);

// used to cut-off some elements (display part of it outside the viewport)
$page-side-offsets: map-merge(
    $page-side-paddings,
    (
        xs: get-map-value($page-side-paddings, xs) * 2,
        md: get-map-value($page-side-paddings, md) * 2,
        xl: get-map-value($page-side-paddings, xxl),
    )
);

$z-index: (
    'jumbotron': 11,
    'heading': 12,
    'main': 15,
    'jumbotron-over-the-main': 16,
    'subheader': 28,
    'header': 29,
    'tooltip': 87,
    'modal': 88,
    'notification': 90,
    'cookies': 99,
);

$transition-time: .5s;

$page-color-bg: $grey-dark;
$page-color-text: $white;

$hyphen-height: 2px;
$hyphen-width: $page-gutter * 2;
$hyphen-color: $color-primary-light;
$hyphen-wide-widths: (
    xs: $page-gutter * 3,
    md: $page-gutter * 4,
    xl: grid-column-width(xl) - get-bp-value($grid-gutters, xl),
);

$hr-width: grid-column-width(lg);
$hr-height: $hyphen-height;
$hr-color: $hyphen-color;

$border-color-light: rgba(#d0d0d0, .45);
$border-color: rgba(#808080, .2);

$image-hover-scale: 1.05;

// *** TYPOGARPHY *** //

$font-base-family: 'Montserrat', 'Arial', sans-serif;
$font-headings-family: $font-base-family;
$font-headings-baseline: .143em;
$font-base-sizes: (
    xs: 14px,
    lg: 14px,
);
$font-base-line-height: 1.5;

$title-font-family: $font-base-family;
$title-baseline: $font-headings-baseline;
$title-line-height: 1.05;
$subtitle-line-height: 1.333;
$title-label-color: inherit;

$text-line-height: 1.85;
$text-minor-line-height: 1.425;

$label-line-height: $font-base-line-height;
$label-color: $color-primary;
$label-secondary-sizes: (
    xs: 14px,
    md: 14px,
    xl: 18px,
);

// Starting from h1 - null takes value from prev breakpoint
$title-sizes: (
    xs: (48px, 48px, 48px, 42px, 21px, 14px),
    md: (64px, 64px, 56px, null, null, 18px),
    xl: (140px, 86px, 78px, 56px, 28px, null),
);

$error-title-sizes: (
    xs: 48px,
    md: 64px,
    xl: 86px,
);

$lead-line-height: $font-base-line-height;
$lead-sizes: (
    xs: 14px,
    md: 16px,
    xl: 18px,
);

// *** LAYOUT ***/
$header-heights: (
    xs: 48px,
    md: 130px,
);
$header-color-bg: $black;
$header-color-text: $white;
$header-color-border: $border-color-light;
$header-nav-item-height: 58px;
$header-nav-heights: (
    xs: 0,
    md: $header-nav-item-height,
);
$nav-color-border: $header-color-border;

$section-paddings: (
    xs: $page-gutter * 2,
    sm: $page-gutter * 3,
    md: $page-gutter * 4,
    lg: $page-gutter * 5,
    xl: $page-gutter * 6,
);

// bg, text
$section-colors: (
    'default': ($page-color-bg, $page-color-text),
    'odd': ($grey-light, #312e2c),
    'even': ($black, $white),
);
$section-color-border: $border-color;

$section-border-start-offset-factor: (
    md: -.25,
    lg: .5,
    xl: 1.5,
);

$section-border-start-up-offset-factor: (
    md: -.25,
    lg: -.25,
    xl: 1,
);

$double-section-color-bg: nth(get-map-value($section-colors, 'odd'), 1);
$double-section-color-text: nth(get-map-value($section-colors, 'odd'), 2);

$modal-max-width: grid-column-width(xl) * 8;
$modal-color-overlay: rgba($black, .7);
$modal-color-bg: $white;
$modal-color-border: $border-color-light;
$modal-color-text: $black;
$modal-header-color-bg: $black;
$modal-header-color-text: $color-primary;
$modal-footer-color-bg: $black;
$modal-footer-color-text: $white;

$footer-color-bg: $black;
$footer-color-text: $white;
$footer-color-border: mix($border-color-light, $footer-color-bg, 65%);
$footer-navigation-padding-xs: 30px;
$footer-navigation-padding-horizontal: 40px;
$footer-navigation-padding-vertical: $page-gutter * 5;
$footer-container-padding: $page-gutter * 2;
$footer-bottom-margin: (
    xs: $page-gutter,
    md: $page-gutter * 7,
    xl: $page-gutter * 7,
);
$footer-social-width: (
    md: 108px,
    lg: 74px,
    xl: 92px,
);
$footer-item-font-size: (
    xs: 10px,
    md: 11px,
    xl: 13px,
);
$footer-bottom-nav-color: #92928f;
$footer-form-max-width: 520px;
$footer-input-border: $footer-color-border;
$footer-input-color: $white;
$footer-button-color: $black;
$footer-button-background: $color-primary;

$social-icons-border: $footer-color-border;
$social-icons-size: $page-gutter * 2;
$social-icons-item-min-height: (
    md: 90px,
    xl: 70px,
);


// *** COMPONENTS ***/
$link-font-sizes: (
    xs: 13px,
);
$link-line-height: $font-base-line-height;
$link-dash-color: $hr-color;
$link-dash-width: $hr-height;
$link-inline-color: $color-primary;

// bg, text
$buttons-types: (
    'default': ($grey-dark, $white),
    'primary': ($color-primary-light, $black),
    'secondary': ($black, $white),
    'icon': (null, $color-primary-light),
    'tertiary': ($white, $black),
);
$button-font-sizes: (
    xs: 13px,
    lg: 14px,
);

$grid-vertical-paddings: (
    xs: $page-gutter * 2,
    md: $page-gutter * 2.5,
    lg: $page-gutter * 3,
    xl: $page-gutter * 3.5,
);

// bravo height - charlie height
$grid-up: (
    xs: 0,
    md: 101px,
    lg: 143px,
    xl: 135px,
);
$grid-content-line-height: 1.667;
$grid-content-font-sizes: (
    xs: 12px,
    xl: 14px,
);

// width, offset left / right, overflow
$box-params: (
    'alpha':   (5, 0, 0, false),
    'bravo':   (6, 0, 0, true),
    'charlie': (4, 1, 1, false),
    'delta':   (6, 0, 0, false),
    'echo':    (6, 1, 0, false),
    'foxtrot': (4, 0, 1, false),
    'golf':    (4, 1, 1, false),
    'hotel':   (5, 0, 0, false),
    'combine-image': (7, 0, 0, false),
    'combine-text':   (7, 0, 0, true),
);
$box-text-offset: $page-gutter;
$box-color-bg: $black;
$box-color-text: $color-primary;
$box-color-icon: $color-primary-light;
$box-color-icon-shadow: rgba($black, .5);
$box-content-offsets: (
    xs: $page-gutter * 2.5,
    md: $page-gutter * 3,
    xl: $page-gutter * 8,
);

$box-text-padding-left: 17.333%; // that's approximation - on designs it's based on grid columns
$box-color-border: $border-color;
$box-link-offset: $page-gutter * 2;

$box-promo-heights: (
    xs: 348px,
    lg: 472px,
);
$box-promo-in-heading-height: 414px;

$tile-image-max-height: 262px;
$tile-image-max-width: 288px;
$tile-image-ratio: percentage($tile-image-max-height / $tile-image-max-width);

$slider-button-color-bg: $black;
$slider-button-color-text: $white;

$carousel-preview-color-bg: $page-color-bg;
$carousel-preview-color-text: $page-color-text;
$carousel-edge-paddings: (
    xs: percentage(1 / 3),
    sm: percentage(1 / 6),
    md: percentage(1 / 9),
    lg: percentage(1 / 12),
    xl: 0,
);

$carousel-dot-size: $page-gutter;
$carousel-dot-color: currentColor;
$carousel-dot-color--active: $color-primary-light;
$carousel-label-extra-color: $color-primary;

$tabs-link-color-text: $color-primary;
$tabs-color-border: $border-color;
$tabs-content-offset: (
    xs: $page-gutter * 5,
    md: $page-gutter * 7.5,
    xl: $page-gutter * 10,
);

$banner-image-heights: (
    xs: 320px,
    md: 500px,
    xl: 700px,
);
$banner-secondary-image-heights: map-merge(
    $banner-image-heights,
    (
        xl: 660px,
    )
);

$banner-color-bg: $black;
$banner-color-text: $white;

$slider-heights: (
    xs: 100vw,
    sm: 50vw,
    xl: 100%,
);

$info-box-colors: (
    'default': (transparent, inherit),
    'odd': (nth(get-map-value($section-colors, 'even'), 1), nth(get-map-value($section-colors, 'even'), 2)),
);

// width, height
$quote-icon-sizes: (
    xs: (58px, 45px),
    md: (48px, 37px),
    xl: (84px, 66px),
);
$quote-signature-size: (
    xs: (80px, 30px),
    md: (84px, 31px),
    xl: (98px, 37px),
);
$quote-font-sizes: (
    xs: 14px,
    md: 16px,
    xl: 18px,
);

$select-font-sizes: (
    xs: 14px,
    md: 16px,
    xl: 18px,
);

$quote-border-color: $border-color;
$quote-icon-color: $color-primary-light;

$standards-symbol-color: $color-primary-light;
$standards-border-bottom: currentColor;
$standards-border: rgba(desaturate($color-primary-light, 100%), .3);


$glyph-font-sizes: $link-font-sizes;
$glyph-line-height: $link-line-height;
$glyph-color-icon: $color-primary-light;
$glyph-icon-size: 36px;
$glyph-icon-size-lg: 54px;

$gallery-min-height: 400px;
$gallery-color-bg: $white;
$gallery-color-text: $black;
$gallery-color-border: $grey-light;

$video-proportion: 9 / 16;
$video-color-bg: $black;
$video-color-text: $white;
$play-color-bg: rgba($black, .3);
$play-color-outline: rgba($white, .1);
$play-color-text: $color-primary-light;

$badge-colors: $promotion;

$media-marker-color-bg: linear-gradient(-35deg, rgb(170, 111, 41) 0%, rgb(243, 203, 131) 47%, rgb(251, 244, 203) 100%);
$media-marker-color-dot: $color-primary;
$media-marker-color-shadow: $black;
$media-marker-animation-time: 2.5s;
$media-marker-animation-delay: .5s;

$tooltip-image-width: 229px;
$tooltip-image-height: 117px;
$tooltip-image-color-bg: $grey-dark;
$tooltip-border-width: 20px;
$tooltip-width: $tooltip-image-width + ($tooltip-border-width * 2);
$tooltip-color-bg: $grey-light;
$tooltip-color-text: $black;
$tooltip-color-border: $white;
$tooltip-arrow-size: $page-gutter / 2;

$tools-color-border: $border-color;
$tools-color-icon: $color-primary-light;

$share-color-icon: $color-primary-light;
$share-color-border: $border-color;

$article-lead-date-color-text: $color-primary;

$player-audio-color-bg: $white;
$player-audio-color-text: $grey-dark;
$player-audio-color-border: #e0deda;
$player-audio-color-icons: $color-primary-light;

$image-and-text-title-color: $color-primary-light;

$simple-teaser-title-color: $color-primary-light;
$simple-teaser-border-color: $border-color;

$simplest-teaser-color-bg: lighten($page-color-bg, 5%);
$simplest-teaser-color-text: $page-color-text;
$simplest-teaser-title-shifts: (
    xs: $page-gutter * 2,
    xl: $page-gutter * 2.5,
);

$two-media-title-color: $color-primary-light;

// Form
$checkbox-color-border: $border-color;
$checkbox-color-mark: $color-primary-light;
$checkbox-mark-svg: svg-url('<svg viewBox="0 0 20 20"><path fill="' + $checkbox-color-mark + '" d="M6 14.7L17 3.3l2 2.1L8 16.7l-2-2zM3 7.4l7 7.3-2 2.1-7-7.3 2-2.1z"/></svg>');
$checkbox-agreement-border: $color-primary-light;
$checkbox-link-color: $color-primary;
$radio-mark-svg: svg-url('<svg viewBox="0 0 20 20"><circle fill="' + $checkbox-color-mark + '" cx="10" cy="10" r="5"/></svg>');
$select-border-color: $color-primary-light;
$select-icon-color: $color-primary-light;
$select-icon-size: 24px;
$form-disclaimer-color: #92928f;
$input-color-bg: $grey-light;
$input-color-text: $black;
$input-color-border: #e7e0d7;
$input-color-error: #92152c;
$input-newsletter-color-bg: $white;
$input-newsletter-color-text: $grey-dark;
$input-newsletter-color-border: transparent;

$map-marker-body: $white;
$map-marker-dot: $color-primary-light;
$map-marker-highlighted-dot: #ded6c8;
$map-marker-selected-body: #d2a057;
$map-marker-selected-dot: #f1c880;

$mega-input-border: #ded6c8;
$mega-input-focus-border: #d19f5d;
$mega-input-color: #1c1a19;

$search-box-input-bg: $white;
$search-box-icon-color: $black;
$search-box-results-border: $grey-dark;
$search-box-results-bg: $black;
$search-box-results-bg-hover: #1c1a19;
$search-box-results-color: $white;
$search-box-error-bg: #701828;
$search-box-error-color: $white;

$contact-details-bg: $black;
$contact-details-button-border: #d09c4a;

$search-input-bg: $grey-light;
$search-input-close-color: $black;

$search-results-query-color: $color-primary-light;

$locator-bg: $grey-light;

$notification-color: $white;
$notification-types: (
    'default': $black,
    'error': #701828,
    'success': #1d3a35,
);

$cookies-color-bg: $white;
$cookies-color-text: #848282;
$cookies-color-icon: $color-primary;
$cookies-font-size: 12px;

$timeline-desc-min-height-lg: 492px;
$timeline-desc-color-bg: $black;
$timeline-desc-color-text: $white;

$timeline-line-width: 2px;
$timeline-line-color: rgba($color-primary, .4);
$timeline-color-hyphen: $white;
$timeline-bullet-size: 2em;
$timeline-bullet-svg: svg-url('<svg viewBox="0 0 34 34"><circle fill="none" stroke-width="2" stroke="' + $color-primary-light + '" cx="17" cy="17" r="16"/><circle fill="' + $color-primary-light + '" cx="17" cy="18" r="3"/></svg>');

$newsletter-button-hover-text: $black;
$newsletter-button-hover-bg: $color-primary;

$document-border-color: rgba(0, 0, 0, .1);
$document-search-border: $mega-input-border;

$embed-ratio: (
    height: 2,
    width: 3,
);
