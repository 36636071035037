.checkbox {
    $root: &;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: .375em 0;
    font: 300 13px/#{1.5} $font-base-family;
    cursor: pointer;

    &__label {
        display: inline-flex;
        align-items: flex-start;
        transition: opacity $transition-time;

        &::before {
            content: '';
            flex-grow: 0;
            flex-shrink: 0;
            width: 1.5em;
            height: 1.5em;
            margin-right: .75em;
            padding: .125em;
            display: inline-block;
            vertical-align: middle;
            border: 1px solid $checkbox-color-border;
        }
    }

    &__copy {
        display: block;
    }

    &__link {
        color: $checkbox-link-color;
        font-weight: bolder;
        text-decoration: underline;
    }

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        &:checked {
            ~ #{$root}__label {
                &::before {
                    content: $checkbox-mark-svg;
                }
            }
        }

        &:disabled {
            ~ #{$root}__label {
                opacity: .5;
            }
        }
    }

    &__required {
        $requiredWidth: .62em;
        display: block;
        text-indent: -$requiredWidth;
    }


    &--agreement {
        #{$root} {
            &__label::before {
                margin-right: $page-gutter * 1.5;
                border-color: $checkbox-agreement-border;
            }
        }
    }

    &--radio {
        #{$root} {
            &__label::before {
                border-radius: 50%;
            }

            &__input {
                &:checked {
                    ~ #{$root}__label {
                        &::before {
                            content: $radio-mark-svg;
                        }
                    }
                }
            }
        }
    }

    &--minor {
        font-size: inherit;

        #{$root} {
            &__label::before {
                width: 2em;
                height: 2em;
            }
        }
    }

    &.-is-not-available {
        display: none;
    }
}
