.notification {
    position: fixed;
    top: 0;
    z-index: z-index('notification');
    width: 100%;
    padding: ($page-gutter * 2.5) 0;
    color: $notification-color;
    font-size: 14px;
    text-align: center;
    transition: opacity $transition-time;
    cursor: pointer;

    @each $type, $bg in $notification-types {
        @if ($type == 'default') {
            background: $bg;
        } @else {
            &--#{$type} {
                background: $bg;
            }
        }
    }

    &.-is-hidden {
        opacity: 0;
    }
}
