.footer-bottom-nav {
    &__logo {
        display: none;
        outline: 0;
    }

    &__items {
        display: flex;
        margin-bottom: $page-gutter * 2;
        color: $footer-bottom-nav-color;
    }

    &__item {
        margin-right: $page-gutter * 3;
    }

    &__link {
        @extend %link-simple;
    }

    &__copyright {
        color: $footer-bottom-nav-color;
        font-size: get-map-value($footer-item-font-size, xs);
    }

    @include media(md) {
        &__items {
            justify-content: flex-start;
            margin-bottom: $page-gutter;
        }

        &__copyright {
            font-size: get-map-value($footer-item-font-size, md);
        }
    }

    @include media(lg) {
        display: flex;
        width: 100%;
        align-items: center;

        &__items {
            margin: 0;
        }

        &__copyright {
            margin-left: auto;
        }
    }

    @include media(xl) {
        &__logo {
            display: block;
            margin-right: $page-gutter * 3;
            width: 79px;
            height: 16px;
            color: inherit;
        }

        &__copyright {
            font-size: get-map-value($footer-item-font-size, xl);
        }
    }
}
