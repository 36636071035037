.social-icons {
    width: 100%;

    &__list {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &__item {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        width: $social-icons-size * 2;
        margin: 0 ($social-icons-size / 2);
    }

    &__link {
        @extend %link-simple;
        flex-grow: 1;
        display: block;
        height: 100%;
        width: 100%;
        text-align: center;
    }

    &__icon {
        svg {
            @extend %svg;
        }
    }


    @include media(sm) {
        &__item {
            margin: 0 $social-icons-size;
        }
    }

    @include media(md) {
        height: 100%;

        &__list {
            height: 100%;
            flex-direction: column;
        }

        &__item {
            width: 100%;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid $social-icons-border;

            &:last-child {
                border: 0;
            }
        }

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__icon {
            height: $social-icons-size;
            width: $social-icons-size;
        }
    }
}
