.link-inline {
    display: inline;
    outline: 0;
    font: inherit;
    font-weight: bolder;
    text-decoration: underline;
    color: $link-inline-color;
    transition: opacity $transition-time;

    &:hover,
    &:focus {
        opacity: .75;
    }
}
