.tabs-list {
    $root: &;
    @extend %container-padding-reset;
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        display: block;
        width: $page-gutter * 4;
        background: linear-gradient(to left, var(--color-bg) 0%, transparent 100%);
        pointer-events: none;
    }

    &::before {
        right: auto;
        left: 0;
        width: $page-gutter * 2;
        background: linear-gradient(to right, var(--color-bg) 0%, transparent 100%);
    }

    &__outer {
        @extend %container-padding-set;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    &__inner {
        position: relative;
        display: flex;
        font: 500 1rem $font-headings-family;
    }

    &__item {
        padding-right: 2em;

        &--tools {
            display: none;
        }
    }

    &__link,
    &__label {
        display: block;
        padding: 1em 0;
        text-transform: uppercase;
        text-decoration: none;
        white-space: nowrap;
        letter-spacing: .3em;
    }

    &__link {
        @extend %border-on-hover;
        color: $tabs-link-color-text;
    }

    @include media(md) {
        &__item {
            &--tools {
                display: flex;
                align-items: center;
                margin-left: auto;
                padding-right: 0;
            }
        }
    }

    @include media(lg) {
        &__item {
            padding-right: 3em;

            &--tools {
                padding-right: 0;
            }
        }
    }
}
