.search-document {
    $root: &;
    padding-left: $page-gutter * 8;

    &__wrapper {
        display: flex;
    }

    &__label {
        position: relative;
        display: inline;
        width: 50%;
        padding: 0;
        background: none;
    }

    &__input {
        width: 100%;
        padding: $page-gutter;
        background: transparent;
        border: none;
        border-bottom: 1px solid $document-search-border;
        font: 200 18px $font-headings-family;
        transition: border-bottom-color .5s;

        &:focus {
            outline: none;
            border-bottom-color: #d19f5d;
        }
    }

    &__icon {
        right: 10px;
    }
}
