.glyph {
    $root: &;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: none;
    color: inherit;
    font: 300 1em/#{$glyph-line-height} $font-headings-family;
    @include each-breakpoint($glyph-font-sizes, font-size);
    text-align: left;
    text-decoration: none;

    &__icon {
        @extend %svg;
        flex-shrink: 0;
        width: $glyph-icon-size;
        height: $glyph-icon-size;
        margin-right: $page-gutter * 2;

        svg {
            fill: url('#glyph-gradient') $glyph-color-icon; // ID of gradient in HTML and fallback color
        }
    }

    &--compact {
        display: inline-flex;
        flex-direction: column;
        padding: 0 $page-gutter;
        text-align: center;

        #{$root}__icon {
            margin-right: 0;
            margin-bottom: $page-gutter * 2;
        }
    }

    &--link {
        cursor: pointer;

        #{$root}__icon {
            position: relative;
            transition: transform $transition-time;
        }

        &:hover,
        &:focus {
            #{$root}__icon {
                transform: translateX($page-gutter / 2);
            }
        }
    }

    @include media(lg) {
        &:not(&--minor) {
            #{$root}__icon {
                width: $glyph-icon-size-lg;
                height: $glyph-icon-size-lg;
            }
        }
    }
}
