.filters {
    @extend %grid;

    &__inner {
        @extend %grid__col;
    }

    &__footer {
        margin-bottom: $page-gutter * 2;
        @include each-breakpoint($section-paddings, (padding-top, padding-bottom), 'divide', 2);
        text-align: right;
    }

    &__list {
        column-count: 1;
        @include each-breakpoint($grid-gutters, column-gap);
    }

    &__item {
        break-inside: avoid;
    }

    @include media(sm) {
        &__list {
            column-count: 2;
        }
    }

    @include media(md) {
        &__inner {
            @extend %grid__col--10-md;
        }
    }

    @include media(lg) {
        &__list {
            column-count: 3;
        }
    }

    @include media(xl) {
        &__inner {
            @extend %grid__col--9-xl;
        }
    }
}
