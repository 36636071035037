.svg-sprite {
    display: block;
    height: 0;
    width: 0;
    overflow: hidden;

    svg {
        visibility: hidden;
    }
}
