.standards {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $page-gutter * 5;

    &__item {
        margin-bottom: $page-gutter;
        padding-right: $page-gutter;
        width: 50%;
    }

    &__link {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        background: none;
        color: inherit;
        font: inherit;
        text-align: left;
        transition: opacity $transition-time;
        cursor: pointer;

        &:hover,
        &:focus {
            opacity: .75;
        }
    }

    &__symbol {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        margin-right: $page-gutter;
        border: 1px solid $standards-border;
        border-bottom: 2px solid $standards-border-bottom;
        font: 500 14px/1 $font-headings-family;
        color: $standards-symbol-color;
    }
}
