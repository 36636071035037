.content {
    $root: &;
    @extend %grid;

    &__inner {
        @extend %grid__col;
        margin: 0 auto;
    }

    &--padded {
        @include each-breakpoint($section-paddings, (padding-top, padding-bottom), 'divide', 2);
    }

    @include media(lg) {
        &--narrow {
            #{$root}__inner {
                @extend %grid__col--10-lg;
            }
        }

        &--narrower {
            #{$root}__inner {
                @extend %grid__col--8-lg;
            }
        }
    }
}
