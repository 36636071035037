.search-input {
    height: 100%;
    padding: $page-gutter 0;
    background: $search-input-bg;

    &__container {
        height: 100%;
    }

    &__inner {
        position: relative;
        height: 100%;
    }

    &__close {
        position: absolute;
        top: 50%;
        right: $page-gutter;
        color: $search-input-close-color;
        transform: translateY(-50%);
        transition: transform $transition-time;
        cursor: pointer;

        &:hover {
            transform: translateY(-50%) rotate(90deg);
        }
    }
}
